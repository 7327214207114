import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import * as NodeLinkTypes from './ReactFlowRenderer/CustomNodes/NodeLinkTypes'
import { removeSpecialSymbols } from '../Logic/cleanNames'

const NodeDragDrop = ({ tableDetails }) => {
  const { table, nodeLink } = tableDetails;
  const [nodeName, setNodeName] = useState('');

  const onDragStart = event => {
    const nodeData = { ...tableDetails, nodeName }
    event.dataTransfer.setData('application/reactflow/nodeData', JSON.stringify(nodeData));
    event.dataTransfer.effectAllowed = 'move';
    setNodeName('');
  };

  const handleData = event => {
    const formattedData = removeSpecialSymbols(event.target.value)
    setNodeName(formattedData);
  }

  const returnPlaceholder = (nodetype) => {
    switch (nodetype) {
      case NodeLinkTypes.HUB_SATELLITE:
        return 'Enter HUB name';
      case NodeLinkTypes.REF_SATELLITE:
        return 'Enter REF name';
      case NodeLinkTypes.XREF:
        return 'Enter XREF name';
      case NodeLinkTypes.HUB_SAT_LINK_LSAT:
        return 'Enter HUB name';
    }
  }

  return (
    <Form onSubmit={event => event.preventDefault()}>
        {( (nodeLink !== NodeLinkTypes.LINK_SATELLITE && nodeLink !== NodeLinkTypes.XLINK_DELTA && nodeLink !== NodeLinkTypes.XLINK_FULL && nodeLink !== '' ) ) && (
          <Form.Group className="mb-2" controlId="inputNode">
            <Form.Label className="visually-hidden">Node Name</Form.Label>
            <Form.Control 
              type="text" 
              placeholder={returnPlaceholder(nodeLink)} 
              value={nodeName} 
              onChange={handleData} 
            />
          </Form.Group>)
        }
        <Form.Group className="mt-4" controlId="dragNode">
          <Form.Label className="visually-hidden">Drag N Drop Node</Form.Label>
          <Form.Control placeholder="Drag & Drop" disabled onDragStart={onDragStart} draggable={(table !== '' && nodeLink !== '' && (nodeName !== '' || nodeLink === NodeLinkTypes.XLINK_DELTA || nodeLink === NodeLinkTypes.LINK_SATELLITE || nodeLink === NodeLinkTypes.XLINK_FULL))} style={{textAlign: 'center', cursor: 'grab'}}/>
        </Form.Group>
      </Form>
  )
}

export default NodeDragDrop