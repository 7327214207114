import * as React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import VideoList from '../Components/VideoList'
import { videos } from '../Constant/VideoList'
import AccordianList from '../Components/AccordianList';

const  DocumentationPage=() =>{
  const [value, setValue] = React.useState('videoList');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} 
           TabIndicatorProps={{
            style: { display: 'none'}
          }}
          
      >
            <Tab sx={{color: 'black'}} className='TabButton' label="Video Tutorial" value="videoList" />
            <Divider sx={{ bgcolor: "black" }} orientation="vertical" variant="middle" flexItem />
            <Tab sx ={{color: 'black'}}className='TabButton' label="Documentation & FAQ" value="doc&faq" />
          </TabList>
        </Box>
        <TabPanel value="videoList"><VideoList videos={videos}/></TabPanel>
        <TabPanel value="doc&faq"><AccordianList /></TabPanel>
      </TabContext>
    </Box>
  );
}



export default DocumentationPage;
