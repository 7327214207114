import React, { useContext, useState, createContext, useEffect } from 'react'

export const AlertContext = createContext(null)

export const AlertProvider = ({ children }) => {
  const alertDelay = 3000
  const [alertsList, setAlertsList] = useState([])
  const alertTypes = {
    CLIENT_ERRORS: 'danger',
    CLIENT_SUCCESS: 'success',
    CLIENT_WARNING: 'warning',
    CLIENT_INFO: 'info',
  }

  useEffect(() => {
    if(alertsList.length > 0) {
      const activeAlert = alertsList[alertsList.length - 1]
      if(activeAlert.msg !== '') {
        if(activeAlert.isDismissible === false) {
          setTimeout(() => removeAlert(), alertDelay)
        }
      } else {
        removeAlert()
      }
    }
  }, [alertsList])

  const addAlert = (alert) => {
    setAlertsList(prevList => {
      const newList = [ ...prevList ]
      let alertExist = false
      alertExist = newList.find(item => {
        return item.msg === alert.msg
      })
      if(alertExist) {
        return prevList
      }
      newList.push(alert)
      return newList
    })

  }

  const removeAlert = () => {
    setAlertsList(prevList => {
      const newList = [ ...prevList ]
      newList.pop()
      return newList
    })
  }


  return (
    <AlertContext.Provider value={{ alertTypes, alertsList, addAlert, removeAlert }}>
      {children}
    </AlertContext.Provider>
  )
}

export const useAlertContext = () => {
  return useContext(AlertContext)
}