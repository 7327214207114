import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomAccordion = ({ summaryComponent, detailsComponent }) => {

  return(
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          {summaryComponent}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {detailsComponent}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default CustomAccordion;