// This component renders the top status bar in the right pane of the Main page
import React from 'react';
import { useAuth } from "../Context/AuthContext"
import {
  HOME,
  TUTORIALS,
  PLAYGROUND,
  BACKLOG
} from '../Types/MenuTypes'
import { useModalContext } from "../Context/ModalContext";
import LogoutDropdown from './LogoutDropdown';

const MainTopBar = ({ selectedMenu }) => {
  const { user } = useAuth()
  const { profileModalShow } = useModalContext()

  // Function to return the current status i.e. the current page based on the menu option selected
  const pageTitle = () => {
    switch (selectedMenu) {
      case HOME:
        return
      case PLAYGROUND:
        return 'Sandbox Environment'
      case TUTORIALS:
        return 'Tutorials & FAQ'
      case BACKLOG:
        return 'Backlog and Priorities'
      default:
        return `Welcome ${user?.attributes?.given_name}`
    }
  }

  return (
    <>
    
    <div className="fs-4 ms-2 fw-bold pageTitle">
        {pageTitle()}
      </div>
      <div className="main-wrapper">
        <div className="user-profile-wrapper">
          <LogoutDropdown top="60" />
        </div>
      </div>
    </>
  )
}

export default MainTopBar;