import React, { useState, useEffect } from 'react'
import { useReactFlowContext } from '../Context/reactFlowContext'
import { useSnowFlakeContext } from '../Context/SnowFlakeContext'
import { saveAs } from 'file-saver'
import { formatName } from '../Logic/cleanNames'
import JSZip from 'jszip'
import {
  DATA,
  DATABASE,
  SCHEMA,
  LABEL,
  TABLE_ABBREVIATION,
  TABLE_REFERENCE,
  OBJECT_SCHEMA,
  OBJECT,
  SELECTED_COLUMNS,
  BUSINESS_KEY,
  SKIP_IN_SYSHASH,
  SOURCE_DELETED_FIELD_INDICATOR,
  TALEND_DELETED_FIELD_INDICATOR,
  SOURCE_DELETED_FIELD_NAME,
  SATELLITE_UPDATED_LABEL,
  XLINK_NAME_UPDATED_LABEL,
  USER_SETTINGS,
  HUB_CONNECTIONS,
  DRIVING_KEY,
  DRIVING_KEY_LABEL,
  SYS_SEQUENCER,
  OVERLOAD_SOURCE_TABLES_OBJECT,
  CONNECTION_TYPE
} from '../Components/ReactFlowRenderer/CustomNodes/NodeLinkTypes'
import { useSocketioContext } from '../Context/SocketioContext'
import { usePlaygroundContext } from '../Context/PlaygroundContext'
import { useAuth } from '../Context/AuthContext'
import { useDesignsContext } from '../Context/DesignsContext'
import { useAlertContext } from '../Context/AlertContext'
import Stack from '@mui/material/Stack'
import CustomDropdown from '../Components/CustomDropdown'
import classNames from 'classnames'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DVA, DBT } from '../Types/FileTypes'
const fileTypes = [DVA, DBT]
const disabledDbtNodeTypes = [ "HUB||SAT", "LINK||LSAT", "REF||SAT", "HUB||SAT||LINK||LSAT" ]
const excludeNewFolderStructureVersions = ["version_1.4.4", "version_1.3.7", "version_1.3.2", "version_1.3.0", "version_1.2.0"]
const disabledDbtNodeTypesOldVersion = ["HUB||SAT", "LINK||LSAT", "REF||SAT", "XREF||FULL", "HUB||SAT||LINK||LSAT"]
const excludeDbtXrefVersions = ["version_1.4.4", "version_1.3.7", "version_1.3.2", "version_1.3.0"]

const ProceduresPage = ({ DDLPrefix, procedurePrefix, origFieldInclude }) => {
  const zip = new JSZip()
  const { socket } = useSocketioContext()
  const [loading, setLoading] = useState(false)
  const [fileType, setFileType] = useState("")
  const [proceduresList, setProceduresList] = useState([])
  const { getNodeById } = useReactFlowContext()
  const { selectedElements } = usePlaygroundContext()
  const { destinationSchema, destinationDataBase, dvaVersion } = useSnowFlakeContext()
  const { currentDesign } = useDesignsContext();
  const { snowflakeAccount, accountsList } = useAuth()
  const { addAlert, alertTypes } = useAlertContext()
  const { t } = useTranslation()
  let processedEntities = []


  useEffect(() => {
    if (dvaVersion === "version_1.2.0") {
      addAlert({ msg: t('CLIENT_INFO.DBT_UNAVAILABLE'), isDismissible: true, variant: alertTypes["CLIENT_INFO"] })
    }
  }, [dvaVersion])

  useEffect(() => {
    if(!excludeDbtXrefVersions.includes(dvaVersion)) {
      // newly updated dbt unsupported nodes alert should be visible only for generator versions v1.5.0 and newer
      if (fileType === fileTypes[1]) {
        const nodeTypeExists = Object.values(selectedElements).find((el) =>
          el["checked"] && disabledDbtNodeTypes.includes(el[CONNECTION_TYPE])
        )
        if (nodeTypeExists) {
          addAlert({ msg: t('CLIENT_INFO.DBT_UNSUPPORTED'), isDismissible: true, variant: alertTypes["CLIENT_INFO"] })
        }
      }
    } else {
      // old dbt unsupported nodes alert
      if (fileType === fileTypes[1]) {
        const nodeTypeExists = Object.values(selectedElements).find((el) =>
          el["checked"] && disabledDbtNodeTypesOldVersion.includes(el[CONNECTION_TYPE])
        )
        if (nodeTypeExists) {
          addAlert({ msg: t('CLIENT_INFO.DBT_UNSUPPORTED_OLD_VERSION'), isDismissible: true, variant: alertTypes["CLIENT_INFO"] })
        }
      }

    }
  }, [selectedElements, fileType])


  const getProcedure = async () => {
    setLoading(true)
    const promises = Object.values(selectedElements).map(async (elementsData) => {
      if (elementsData["checked"]) {
        const procedureType = elementsData["connectionType"]
        const procedureEntities = elementsData["connectedEntities"]
        processedEntities.push(procedureEntities)
        let hubNode, satelliteNode, linkNode, lsatelliteNode, abbreviationObjectSchema, objectName, hubLabel, formattedHubLabel, requestPayload, sysSequencer, sourceSchema
        switch (procedureType) {
          case "HUB||SAT":
            hubNode = getNodeById(procedureEntities[0])
            satelliteNode = getNodeById(procedureEntities[1])
            abbreviationObjectSchema = satelliteNode[DATA][USER_SETTINGS][OBJECT_SCHEMA].replace(/^CONFIG_|_OBJECT_SCHEMA$/g, '')
            hubLabel = hubNode[DATA][LABEL]
            formattedHubLabel = hubLabel.replace(/^HUB_/, '')
            objectName = satelliteNode[DATA][USER_SETTINGS][OBJECT]
            requestPayload = {}
            requestPayload['ACCOUNT'] = accountsList[snowflakeAccount]?.accountURL
            requestPayload.TABLE_ID = satelliteNode.id;
            requestPayload.DESIGN_NAME = currentDesign.designId;
            requestPayload['DATABASE'] = satelliteNode[DATA][DATABASE];
            requestPayload['DEST_SCHEMA'] = destinationSchema;
            requestPayload["DEST_DATABASE"] = destinationDataBase;
            requestPayload['ITEMS'] = {}
            const itemsObject = {}
            itemsObject['OBJECT'] = satelliteNode[DATA][USER_SETTINGS][OBJECT];
            sourceSchema = satelliteNode[DATA][SCHEMA];
            itemsObject['PSA_SOURCE'] = sourceSchema;
            itemsObject['PYTHON_INPUT'] = {};
            itemsObject['PYTHON_INPUT']['TABLE_ABBREVIATION'] = satelliteNode[DATA][USER_SETTINGS][TABLE_ABBREVIATION];
            itemsObject['PYTHON_INPUT']['TABLE_REFERENCE'] = satelliteNode[DATA][USER_SETTINGS][TABLE_REFERENCE];
            if (satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== undefined && satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== '') {
              itemsObject['PYTHON_INPUT']['SAT_NAME'] = satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL]
            }
            itemsObject['PYTHON_INPUT']['NAME'] = formattedHubLabel
            itemsObject['BUSINESS_KEY'] = {}
            itemsObject['BUSINESS_KEY'][formattedHubLabel] = satelliteNode[DATA][USER_SETTINGS][BUSINESS_KEY]
            itemsObject['SELECTED_COLUMNS'] = satelliteNode[DATA][USER_SETTINGS][SELECTED_COLUMNS]
            itemsObject['DV_TABLE_TYPE'] = procedureType
            itemsObject['SKIP_IN_SYSHASH'] = satelliteNode[DATA][USER_SETTINGS][SKIP_IN_SYSHASH]
            itemsObject['SOURCE_DELETED_FIELD'] = satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR].toString()
            if (satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true) {
              itemsObject['SOURCE_DELETED_FIELD_NAME'] = satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME]
            } else {
              itemsObject['SOURCE_DELETED_FIELD_NAME'] = satelliteNode[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR].toString()
            }
            itemsObject['ABBREVIATION_OBJECT_SCHEMA'] = abbreviationObjectSchema
            sysSequencer = satelliteNode[DATA][USER_SETTINGS][SYS_SEQUENCER]
            if (sysSequencer.length > 0) itemsObject['SYS_SEQUENCER_COLUMNS'] = sysSequencer
            else itemsObject['SYS_SEQUENCER_COLUMNS'] = []
            itemsObject['DRIVING_KEY'] = ''
            if (satelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] !== undefined && Object.keys(satelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).length > 0) {
              itemsObject['DV_TABLE_TYPE'] = 'HUB||OVERLOADED||SAT'
              requestPayload['ITEMS'][objectName] = { ...itemsObject }
              Object.keys(satelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).forEach(loadedTable => {
                const newObject = { ...itemsObject }
                const newObjectName = satelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT][loadedTable]
                newObject['OBJECT'] = satelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT][loadedTable]
                const pythonInputObject = { ...newObject['PYTHON_INPUT'] }
                pythonInputObject['TABLE_REFERENCE'] = newObject['OBJECT']
                newObject['PYTHON_INPUT'] = { ...pythonInputObject }
                requestPayload['ITEMS'][newObjectName] = { ...newObject }
              })
            } else {
              requestPayload['ITEMS'][objectName] = { ...itemsObject }
            }
            //Socket event 'generateProcedures' requested by client to generate procedures with payload data at server
            await new Promise((resolve, reject) => {
              callGenerateProcedure(requestPayload, sourceSchema, destinationSchema, resolve, reject)
            })
            break;
          case "REF||SAT":
            hubNode = getNodeById(procedureEntities[0])
            satelliteNode = getNodeById(procedureEntities[1])
            abbreviationObjectSchema = satelliteNode[DATA][USER_SETTINGS][OBJECT_SCHEMA].replace(/^CONFIG_|_OBJECT_SCHEMA$/g, '')
            hubLabel = hubNode[DATA][LABEL]
            formattedHubLabel = hubLabel.replace(/^REF_/, '')
            objectName = satelliteNode[DATA][USER_SETTINGS][OBJECT]
            requestPayload = {}
            requestPayload['ACCOUNT'] = accountsList[snowflakeAccount]?.accountURL
            requestPayload.TABLE_ID = satelliteNode.id;
            requestPayload.DESIGN_NAME = currentDesign.designId;
            requestPayload['DATABASE'] = satelliteNode[DATA][DATABASE];
            requestPayload['DEST_SCHEMA'] = destinationSchema;
            requestPayload["DEST_DATABASE"] = destinationDataBase;
            requestPayload['ITEMS'] = {}
            requestPayload['ITEMS'][objectName] = {}
            requestPayload['ITEMS'][objectName]['OBJECT'] = satelliteNode[DATA][USER_SETTINGS][OBJECT];
            sourceSchema = satelliteNode[DATA][SCHEMA];
            requestPayload['ITEMS'][objectName]['PSA_SOURCE'] = sourceSchema;
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT'] = {};
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['TABLE_ABBREVIATION'] = satelliteNode[DATA][USER_SETTINGS][TABLE_ABBREVIATION];
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['TABLE_REFERENCE'] = satelliteNode[DATA][USER_SETTINGS][TABLE_REFERENCE];
            if (satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== undefined && satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== '') {
              requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['SAT_NAME'] = satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL]
            }
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['NAME'] = formattedHubLabel
            requestPayload['ITEMS'][objectName]['BUSINESS_KEY'] = {}
            requestPayload['ITEMS'][objectName]['BUSINESS_KEY'][formattedHubLabel] = satelliteNode[DATA][USER_SETTINGS][BUSINESS_KEY]
            requestPayload['ITEMS'][objectName]['SELECTED_COLUMNS'] = satelliteNode[DATA][USER_SETTINGS][SELECTED_COLUMNS]
            requestPayload['ITEMS'][objectName]['DV_TABLE_TYPE'] = procedureType
            requestPayload['ITEMS'][objectName]['SKIP_IN_SYSHASH'] = satelliteNode[DATA][USER_SETTINGS][SKIP_IN_SYSHASH]
            requestPayload['ITEMS'][objectName]['SOURCE_DELETED_FIELD'] = satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR].toString()
            if (satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true) {
              requestPayload['ITEMS'][objectName]['SOURCE_DELETED_FIELD_NAME'] = satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME]
            } else {
              requestPayload['ITEMS'][objectName]['SOURCE_DELETED_FIELD_NAME'] = satelliteNode[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR].toString()
            }
            requestPayload['ITEMS'][objectName]['ABBREVIATION_OBJECT_SCHEMA'] = abbreviationObjectSchema
            sysSequencer = satelliteNode[DATA][USER_SETTINGS][SYS_SEQUENCER]
            if (sysSequencer.length > 0) requestPayload['ITEMS'][objectName]['SYS_SEQUENCER_COLUMNS'] = sysSequencer
            else requestPayload['ITEMS'][objectName]['SYS_SEQUENCER_COLUMNS'] = []
            requestPayload['ITEMS'][objectName]['DRIVING_KEY'] = ''
            //Socket event 'generateProcedures' requested by client to generate procedures with payload data at server
            await new Promise((resolve, reject) => {
              callGenerateProcedure(requestPayload, sourceSchema, destinationSchema, resolve, reject)
            })
            break;
          case "XREF||FULL":
            hubNode = satelliteNode = getNodeById(procedureEntities[0])
            abbreviationObjectSchema = satelliteNode[DATA][USER_SETTINGS][OBJECT_SCHEMA].replace(/^CONFIG_|_OBJECT_SCHEMA$/g, '')
            hubLabel = hubNode[DATA][LABEL]
            formattedHubLabel = hubLabel.replace(/^XREF_/, '')
            objectName = satelliteNode[DATA][USER_SETTINGS][OBJECT]
            requestPayload = {}
            requestPayload['ACCOUNT'] = accountsList[snowflakeAccount]?.accountURL
            requestPayload.TABLE_ID = satelliteNode.id;
            requestPayload.DESIGN_NAME = currentDesign.designId;
            requestPayload['DATABASE'] = satelliteNode[DATA][DATABASE];
            requestPayload['DEST_SCHEMA'] = destinationSchema;
            requestPayload["DEST_DATABASE"] = destinationDataBase;
            requestPayload['ITEMS'] = {}
            requestPayload['ITEMS'][objectName] = {}
            requestPayload['ITEMS'][objectName]['OBJECT'] = satelliteNode[DATA][USER_SETTINGS][OBJECT];
            sourceSchema = satelliteNode[DATA][SCHEMA];
            requestPayload['ITEMS'][objectName]['PSA_SOURCE'] = sourceSchema;
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT'] = {};
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['TABLE_ABBREVIATION'] = satelliteNode[DATA][USER_SETTINGS][TABLE_ABBREVIATION];
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['TABLE_REFERENCE'] = satelliteNode[DATA][USER_SETTINGS][TABLE_REFERENCE];
            if (satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== undefined && satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== '') {
              requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['SAT_NAME'] = satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL]
            }
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['NAME'] = formattedHubLabel
            requestPayload['ITEMS'][objectName]['BUSINESS_KEY'] = {}
            requestPayload['ITEMS'][objectName]['BUSINESS_KEY'][formattedHubLabel] = satelliteNode[DATA][USER_SETTINGS][BUSINESS_KEY]
            requestPayload['ITEMS'][objectName]['SELECTED_COLUMNS'] = satelliteNode[DATA][USER_SETTINGS][SELECTED_COLUMNS]
            requestPayload['ITEMS'][objectName]['DV_TABLE_TYPE'] = procedureType
            requestPayload['ITEMS'][objectName]['SKIP_IN_SYSHASH'] = satelliteNode[DATA][USER_SETTINGS][SKIP_IN_SYSHASH]
            requestPayload['ITEMS'][objectName]['SOURCE_DELETED_FIELD'] = satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR].toString()
            if (satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true) {
              requestPayload['ITEMS'][objectName]['SOURCE_DELETED_FIELD_NAME'] = satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME]
            } else {
              requestPayload['ITEMS'][objectName]['SOURCE_DELETED_FIELD_NAME'] = satelliteNode[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR].toString()
            }
            requestPayload['ITEMS'][objectName]['ABBREVIATION_OBJECT_SCHEMA'] = abbreviationObjectSchema
            sysSequencer = satelliteNode[DATA][USER_SETTINGS][SYS_SEQUENCER]
            if (sysSequencer.length > 0) requestPayload['ITEMS'][objectName]['SYS_SEQUENCER_COLUMNS'] = sysSequencer
            else requestPayload['ITEMS'][objectName]['SYS_SEQUENCER_COLUMNS'] = []
            requestPayload['ITEMS'][objectName]['DRIVING_KEY'] = ''
            //Socket event 'generateProcedures' requested by client to generate procedures with payload data at server
            await new Promise((resolve, reject) => {
              callGenerateProcedure(requestPayload, sourceSchema, destinationSchema, resolve, reject)
            })
            break;
          case "LINK||LSAT":
            linkNode = getNodeById(procedureEntities[0])
            lsatelliteNode = getNodeById(procedureEntities[1])
            abbreviationObjectSchema = lsatelliteNode[DATA][USER_SETTINGS][OBJECT_SCHEMA].replace(/^CONFIG_|_OBJECT_SCHEMA$/g, '')
            objectName = lsatelliteNode[DATA][USER_SETTINGS][OBJECT]
            requestPayload = {}
            requestPayload['ACCOUNT'] = accountsList[snowflakeAccount]?.accountURL
            requestPayload.TABLE_ID = lsatelliteNode.id;
            requestPayload.DESIGN_NAME = currentDesign.designId;
            requestPayload['DATABASE'] = lsatelliteNode[DATA][DATABASE];
            requestPayload['DEST_SCHEMA'] = destinationSchema;
            requestPayload["DEST_DATABASE"] = destinationDataBase;
            requestPayload['ITEMS'] = {}
            const itemsObjectLink = {}
            itemsObjectLink['OBJECT'] = lsatelliteNode[DATA][USER_SETTINGS][OBJECT];
            sourceSchema = lsatelliteNode[DATA][SCHEMA];
            itemsObjectLink['PSA_SOURCE'] = sourceSchema;
            itemsObjectLink['PYTHON_INPUT'] = {};
            itemsObjectLink['PYTHON_INPUT']['TABLE_ABBREVIATION'] = lsatelliteNode[DATA][USER_SETTINGS][TABLE_ABBREVIATION];
            itemsObjectLink['PYTHON_INPUT']['TABLE_REFERENCE'] = lsatelliteNode[DATA][USER_SETTINGS][TABLE_REFERENCE];
            if (lsatelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== undefined && lsatelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== '') {
              itemsObjectLink['PYTHON_INPUT']['SAT_NAME'] = lsatelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL]
            }
            const formattedHubLabelList = []
            linkNode[DATA][HUB_CONNECTIONS]["hubOrder"].forEach(hubId => {
              const formattedHubLabel = linkNode[DATA][HUB_CONNECTIONS][hubId].replace(/^HUB_/, '')
              formattedHubLabelList.push(formattedHubLabel)
            })

            itemsObjectLink['PYTHON_INPUT']['NAME'] = formattedHubLabelList
            itemsObjectLink['BUSINESS_KEY'] = lsatelliteNode[DATA][USER_SETTINGS][BUSINESS_KEY]
            itemsObjectLink['SELECTED_COLUMNS'] = lsatelliteNode[DATA][USER_SETTINGS][SELECTED_COLUMNS]
            itemsObjectLink['DV_TABLE_TYPE'] = procedureType
            itemsObjectLink['SKIP_IN_SYSHASH'] = lsatelliteNode[DATA][USER_SETTINGS][SKIP_IN_SYSHASH]
            itemsObjectLink['SOURCE_DELETED_FIELD'] = lsatelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR].toString()
            if (lsatelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true) {
              itemsObjectLink['SOURCE_DELETED_FIELD_NAME'] = lsatelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME]
            } else {
              itemsObjectLink['SOURCE_DELETED_FIELD_NAME'] = lsatelliteNode[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR].toString()
            }
            itemsObjectLink['ABBREVIATION_OBJECT_SCHEMA'] = abbreviationObjectSchema
            itemsObjectLink['SYS_SEQUENCER_COLUMNS'] = []
            itemsObjectLink['DRIVING_KEY'] = {}
            itemsObjectLink['DRIVING_KEY']['NAME'] = lsatelliteNode[DATA][USER_SETTINGS][DRIVING_KEY_LABEL]
            itemsObjectLink['DRIVING_KEY']['COLUMNS'] = lsatelliteNode[DATA][USER_SETTINGS][DRIVING_KEY]
            if (lsatelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] !== undefined && Object.keys(lsatelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).length > 0) {
              itemsObjectLink['DV_TABLE_TYPE'] = 'LINK||OVERLOADED||LSAT'
              requestPayload['ITEMS'][objectName] = { ...itemsObjectLink }
              Object.keys(lsatelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).forEach(loadedTable => {
                const newObject = { ...itemsObjectLink }
                const newObjectName = lsatelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT][loadedTable]
                newObject['OBJECT'] = lsatelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT][loadedTable]
                const pythonInputObject = { ...newObject['PYTHON_INPUT'] }
                pythonInputObject['TABLE_REFERENCE'] = newObject['OBJECT']
                newObject['PYTHON_INPUT'] = { ...pythonInputObject }
                requestPayload['ITEMS'][newObjectName] = { ...newObject }
              })
            } else {
              requestPayload['ITEMS'][objectName] = { ...itemsObjectLink }
            }
            //Socket event 'generateProcedures' requested by client to generate procedures with payload data at server
            await new Promise((resolve, reject) => {
              callGenerateProcedure(requestPayload, sourceSchema, destinationSchema, resolve, reject)
            })
            break;
          case "HUB||SAT||LINK||LSAT":
            hubNode = getNodeById(procedureEntities[0])
            satelliteNode = getNodeById(procedureEntities[1])
            linkNode = getNodeById(procedureEntities[2])
            lsatelliteNode = getNodeById(procedureEntities[3])
            abbreviationObjectSchema = satelliteNode[DATA][USER_SETTINGS][OBJECT_SCHEMA].replace(/^CONFIG_|_OBJECT_SCHEMA$/g, '')
            objectName = satelliteNode[DATA][USER_SETTINGS][OBJECT]
            requestPayload = {}
            requestPayload['ACCOUNT'] = accountsList[snowflakeAccount]?.accountURL
            requestPayload.TABLE_ID = satelliteNode.id;
            requestPayload.DESIGN_NAME = currentDesign.designId;
            requestPayload['DATABASE'] = satelliteNode[DATA][DATABASE];
            requestPayload['DEST_SCHEMA'] = destinationSchema;
            requestPayload["DEST_DATABASE"] = destinationDataBase;
            requestPayload['ITEMS'] = {}
            const itemsObjectMulti = {}
            itemsObjectMulti['OBJECT'] = satelliteNode[DATA][USER_SETTINGS][OBJECT];
            sourceSchema = satelliteNode[DATA][SCHEMA];
            itemsObjectMulti['PSA_SOURCE'] = sourceSchema;
            itemsObjectMulti['PYTHON_INPUT'] = {};
            itemsObjectMulti['PYTHON_INPUT']['TABLE_ABBREVIATION'] = satelliteNode[DATA][USER_SETTINGS][TABLE_ABBREVIATION];
            itemsObjectMulti['PYTHON_INPUT']['TABLE_REFERENCE'] = satelliteNode[DATA][USER_SETTINGS][TABLE_REFERENCE];

            if ((satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== undefined && satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== '') || (lsatelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== undefined && lsatelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== '')) {
              itemsObjectMulti['PYTHON_INPUT']['SAT_NAME'] = {}
              if (satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== undefined && satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== '') {
                itemsObjectMulti['PYTHON_INPUT']['SAT_NAME']["SAT"] = satelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL]
              }
              if (lsatelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== undefined && lsatelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== '') {
                itemsObjectMulti['PYTHON_INPUT']['SAT_NAME']["LSAT"] = lsatelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL]
              }
            }

            const formattedHubLabelListMany = []
            linkNode[DATA][HUB_CONNECTIONS]["hubOrder"].forEach(hubId => {
              const formattedHubLabel = linkNode[DATA][HUB_CONNECTIONS][hubId].replace(/^HUB_/, '')
              formattedHubLabelListMany.push(formattedHubLabel)
            })

            itemsObjectMulti['PYTHON_INPUT']['NAME'] = formattedHubLabelListMany
            itemsObjectMulti['BUSINESS_KEY'] = lsatelliteNode[DATA][USER_SETTINGS][BUSINESS_KEY]
            itemsObjectMulti['SELECTED_COLUMNS'] = lsatelliteNode[DATA][USER_SETTINGS][SELECTED_COLUMNS]
            itemsObjectMulti['DV_TABLE_TYPE'] = procedureType
            itemsObjectMulti['SKIP_IN_SYSHASH'] = satelliteNode[DATA][USER_SETTINGS][SKIP_IN_SYSHASH]
            itemsObjectMulti['SOURCE_DELETED_FIELD'] = satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR].toString()
            if (satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true) {
              itemsObjectMulti['SOURCE_DELETED_FIELD_NAME'] = satelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME]
            } else {
              itemsObjectMulti['SOURCE_DELETED_FIELD_NAME'] = satelliteNode[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR].toString()
            }
            itemsObjectMulti['ABBREVIATION_OBJECT_SCHEMA'] = abbreviationObjectSchema
            itemsObjectMulti['SYS_SEQUENCER_COLUMNS'] = []
            itemsObjectMulti['DRIVING_KEY'] = {}
            itemsObjectMulti['DRIVING_KEY']['NAME'] = lsatelliteNode[DATA][USER_SETTINGS][DRIVING_KEY_LABEL]
            itemsObjectMulti['DRIVING_KEY']['COLUMNS'] = lsatelliteNode[DATA][USER_SETTINGS][DRIVING_KEY]
            if (satelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] !== undefined && Object.keys(satelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).length > 0) {
              itemsObjectMulti['DV_TABLE_TYPE'] = 'HUB||OVERLOADED||SAT||LINK||LSAT'
              requestPayload['ITEMS'][objectName] = { ...itemsObjectMulti }
              Object.keys(satelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).forEach(loadedTable => {
                const newObject = { ...itemsObjectMulti }
                const newObjectName = satelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT][loadedTable]
                newObject['OBJECT'] = satelliteNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT][loadedTable]
                const pythonInputObject = { ...newObject['PYTHON_INPUT'] }
                pythonInputObject['TABLE_REFERENCE'] = newObject['OBJECT']
                newObject['PYTHON_INPUT'] = { ...pythonInputObject }
                requestPayload['ITEMS'][newObjectName] = { ...newObject }
              })
            } else {
              requestPayload['ITEMS'][objectName] = { ...itemsObjectMulti }
            }
            //Socket event 'generateProcedures' requested by client to generate procedures with payload data at server
            await new Promise((resolve, reject) => {
              callGenerateProcedure(requestPayload, sourceSchema, destinationSchema, resolve, reject)
            })
            break;
          case "XLINK||DELTA":
          case "XLINK||FULL":
            lsatelliteNode = linkNode = getNodeById(procedureEntities[0])
            abbreviationObjectSchema = lsatelliteNode[DATA][USER_SETTINGS][OBJECT_SCHEMA].replace(/^CONFIG_|_OBJECT_SCHEMA$/g, '')
            objectName = lsatelliteNode[DATA][USER_SETTINGS][OBJECT]
            requestPayload = {}
            requestPayload['ACCOUNT'] = accountsList[snowflakeAccount]?.accountURL
            requestPayload.TABLE_ID = lsatelliteNode.id;
            requestPayload.DESIGN_NAME = currentDesign.designId;
            requestPayload['DATABASE'] = lsatelliteNode[DATA][DATABASE];
            requestPayload['DEST_SCHEMA'] = destinationSchema;
            requestPayload["DEST_DATABASE"] = destinationDataBase;
            requestPayload['ITEMS'] = {}
            requestPayload['ITEMS'][objectName] = {}
            requestPayload['ITEMS'][objectName]['OBJECT'] = lsatelliteNode[DATA][USER_SETTINGS][OBJECT];
            sourceSchema = lsatelliteNode[DATA][SCHEMA];
            requestPayload['ITEMS'][objectName]['PSA_SOURCE'] = sourceSchema;
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT'] = {};
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['TABLE_ABBREVIATION'] = lsatelliteNode[DATA][USER_SETTINGS][TABLE_ABBREVIATION];
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['TABLE_REFERENCE'] = lsatelliteNode[DATA][USER_SETTINGS][TABLE_REFERENCE];
            if (lsatelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== undefined && lsatelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] !== '') {
              requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['SAT_NAME'] = lsatelliteNode[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL]
            }
            const xformattedHubLabelList = []
            linkNode[DATA][HUB_CONNECTIONS]["hubOrder"].forEach(hubId => {
              const formattedHubLabel = linkNode[DATA][HUB_CONNECTIONS][hubId].replace(/^HUB_/, '')
              xformattedHubLabelList.push(formattedHubLabel)
            })
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['XLINK_NAME'] = lsatelliteNode[DATA][USER_SETTINGS][XLINK_NAME_UPDATED_LABEL]
            requestPayload['ITEMS'][objectName]['PYTHON_INPUT']['NAME'] = xformattedHubLabelList
            requestPayload['ITEMS'][objectName]['BUSINESS_KEY'] = lsatelliteNode[DATA][USER_SETTINGS][BUSINESS_KEY]
            requestPayload['ITEMS'][objectName]['SELECTED_COLUMNS'] = lsatelliteNode[DATA][USER_SETTINGS][SELECTED_COLUMNS]
            requestPayload['ITEMS'][objectName]['DV_TABLE_TYPE'] = procedureType
            requestPayload['ITEMS'][objectName]['SKIP_IN_SYSHASH'] = lsatelliteNode[DATA][USER_SETTINGS][SKIP_IN_SYSHASH]
            requestPayload['ITEMS'][objectName]['SOURCE_DELETED_FIELD'] = lsatelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR].toString()
            if (lsatelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true) {
              requestPayload['ITEMS'][objectName]['SOURCE_DELETED_FIELD_NAME'] = lsatelliteNode[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME]
            } else {
              requestPayload['ITEMS'][objectName]['SOURCE_DELETED_FIELD_NAME'] = lsatelliteNode[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR].toString()
            }
            requestPayload['ITEMS'][objectName]['ABBREVIATION_OBJECT_SCHEMA'] = abbreviationObjectSchema
            requestPayload['ITEMS'][objectName]['SYS_SEQUENCER_COLUMNS'] = []
            requestPayload['ITEMS'][objectName]['DRIVING_KEY'] = ""
            //Socket event 'generateProcedures' requested by client to generate procedures with payload data at server
            await new Promise((resolve, reject) => {
              callGenerateProcedure(requestPayload, sourceSchema, destinationSchema, resolve, reject)
            })
            break;
          default:
            break;
        }
      }
    })
    await Promise.allSettled(promises)
    addAlert({ msg: t('CLIENT_SUCCESS.PROCEDURES_GENERATED'), isDismissible: false, variant: alertTypes["CLIENT_SUCCESS"] })
    setLoading(false)
  }

  const addProcedure = (name, procedure, checkExistingAndUpdate) => {
    const newProcedure = {
      name,
      procedure
    }
    setProceduresList(prevList => {
      if (!checkExistingAndUpdate) {
        return ([...prevList, newProcedure])
      }
      let matchedSourceYamlIndex = 0
      const duplicateSourceYaml = prevList.find((item, index) => {
        if (item.name === name) {
          matchedSourceYamlIndex = index
          return true
        }
        return false
      })
      if (duplicateSourceYaml !== undefined) {
        const descriptionRegexPattern = /description: .*?\n/
        const uniqueKeyRegexPattern = /unique_key: .*\n/
        const columnListRegexPattern = /combination_of_columns: .*\n/

        const newHubName = procedure.match(descriptionRegexPattern)[0].split(' - ')[2]
        const existingDescription = duplicateSourceYaml.procedure.match(descriptionRegexPattern)[0]
        const newDescription = `${existingDescription.slice(0, -1)}, ${newHubName}`

        const oldColumns = duplicateSourceYaml.procedure.match(uniqueKeyRegexPattern)[0].slice(0, -3).split("['SYS_LOADED_ID', '")[1].split("', '")
        const newColumns = procedure.match(uniqueKeyRegexPattern)[0].slice(0, -3).split("['SYS_LOADED_ID', '")[1].split("', '")
        const finalColumns = Array.from(new Set([...oldColumns, ...newColumns]))

        const updatedUniqueKey = `unique_key: ['SYS_LOADED_ID', '${finalColumns.join("', '")}']\n`
        const updatedColumnsList = `combination_of_columns: ['SYS_LOADED_ID', 'JSON_STRING:"${finalColumns.join(`"', 'JSON_STRING:"`)}"']\n`

        const updatedFinalProcedure = duplicateSourceYaml.procedure.replace(descriptionRegexPattern, newDescription).replace(uniqueKeyRegexPattern, updatedUniqueKey).replace(columnListRegexPattern, updatedColumnsList)

        let updatedProcedureList = [...prevList]
        updatedProcedureList[matchedSourceYamlIndex] = { name, procedure: updatedFinalProcedure }

        return updatedProcedureList
      }
      return ([...prevList, newProcedure])
    })
  }

  const callGenerateProcedure = (requestPayload, sourceSchema, destinationSchema, resolve, reject) => {
    //Hard coded "VERSION" is mentioned in the payload and it needs to be replaced in the Versioning UI ticket
    socket.emit('generateProcedures', { ...requestPayload, FILE_TYPE: fileType, VERSION: dvaVersion, ORIG_FIELD_INCLUDE: origFieldInclude },
      (responsePayload) => {
        if (responsePayload?.error !== undefined) reject()
        else {
          const receivedProcedures = responsePayload.procedures
          const procedureNames = Object.keys(receivedProcedures)
          procedureNames.forEach(procedureName => {
            const { table_name, ddl, dml, dbtSql, dbtSources, dbtModels } = receivedProcedures[procedureName]
            const formattedTableName = formatName(table_name)
            const formattedSourceSchema = formatName(sourceSchema.toLowerCase())
            const formattedDestinationSchema = formatName(destinationSchema.toLowerCase())

            if(!excludeNewFolderStructureVersions.includes(dvaVersion)) {
              // generate files as per DO@R standards
              if (ddl && dml) {
                const formattedProcedureName = formatName(procedureName)
                addProcedure(`Snowflake/TABLES/${formattedDestinationSchema}/${DDLPrefix}${formattedTableName}.sql`, ddl, false)
                addProcedure(`Snowflake/PROCEDURES/${formattedDestinationSchema}/${procedurePrefix}${formattedProcedureName}.sql`, dml, false)
              }
              if (dbtSql && dbtSources && dbtModels) {
                const formattedDbtSourcesName = formatName(dbtSources.name)
                const formattedDbtModelsName = formatName(dbtModels.name)
  
                addProcedure(`dbt/sources/${formattedSourceSchema}/${formattedDbtSourcesName}.yml`, dbtSources.yml, true)
                addProcedure(`dbt/models/${formattedDestinationSchema}/${formattedTableName.toLowerCase()}.sql`, dbtSql, false)
                addProcedure(`dbt/models/${formattedDestinationSchema}/${formattedDbtModelsName}.yml`, dbtModels.yml, false)
              }
            } else {
              // generate files in default manner
              if (ddl && dml) {
                const formattedProcedureName = formatName(procedureName)
                addProcedure(`${DDLPrefix}${formattedTableName}.sql`, ddl, false)
                addProcedure(`${procedurePrefix}${formattedProcedureName}.sql`, dml, false)
              }
              if (dbtSql && dbtSources && dbtModels) {
                const formattedDbtSourcesName = formatName(dbtSources.name)
                const formattedDbtModelsName = formatName(dbtModels.name)
  
                addProcedure(`modelling/sources/${formattedSourceSchema}/${formattedDbtSourcesName}.yml`, dbtSources.yml, true)
                addProcedure(`modelling/models/${formattedDestinationSchema}/${formattedTableName.toLowerCase()}.sql`, dbtSql, false)
                addProcedure(`modelling/models/${formattedDestinationSchema}/${formattedDbtModelsName}.yml`, dbtModels.yml, false)
              }
            }
          })
          resolve(responsePayload)
        }
      }
    )
  }

  const handleDownload = () => {
    proceduresList.forEach(procedureData => {
      const { name, procedure } = procedureData
      const blob = new Blob([procedure], { type: "text/plain;charset=utf-8" })
      zip.file(`${name}`, blob)
    })
    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        saveAs(content, 'data-vault-automation.zip')
      })
  }

  const handleFileSelection = (value) => {
    setProceduresList([])
    setFileType(value)
  }

  const generateFileButtonDisabled = fileType === "" || loading || proceduresList.length
  const downloadFileButtonDisabled = loading || !proceduresList.length

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100">
      <div className="text-center">
        <h2 className="fw-bold mb-2">File Generator</h2>
        <p className="fw-semibold mb-2">Please select the file type you want to download</p>
        <div className="p-4">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            className="mb-3"
          >
            <div>File Type</div>
            <div>
              <CustomDropdown
                dropdownList={dvaVersion === "version_1.2.0" ? fileTypes.slice(0, 1) : fileTypes}
                dropdownName="File Type"
                handleSelection={(value) => handleFileSelection(value)}
                selectedValue={fileType}
                filterable={false}
                clipLength={20}
                customClassname="procedure-page-dropdown"
                needToBeAuthorized={false}
              />
            </div>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Tooltip
              title={generateFileButtonDisabled ? "Select file type to generate files" : null}
              placement="bottom"
              arrow
            >
              <span>
                <button className={classNames('procedure-page-button', { 'procedure-page-button-disabled': generateFileButtonDisabled })} size="md" disabled={generateFileButtonDisabled} onClick={getProcedure}>
                  Generate Files
                </button>
              </span>
            </Tooltip>
            <Tooltip
              title={downloadFileButtonDisabled ? "Download will be available once files are generated" : null}
              placement="bottom"
              arrow
            >
              <span>
                <button className={classNames('procedure-page-button', { 'procedure-page-button-disabled': downloadFileButtonDisabled })} size="md" disabled={downloadFileButtonDisabled} onClick={handleDownload}>
                  {`Download ${fileType} Files`}
                </button>
              </span>
            </Tooltip>
          </Stack>
        </div>
        {loading
          ? (<div>
            <div
              class="spinner-grow text-primary"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span class="sr-only"> </span>
            </div>
            <div className="mt-1">Generating files...</div>
          </div>)
          : null
        }
      </div>
    </div>
  )
}

export default ProceduresPage