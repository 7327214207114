import React, { useEffect } from 'react';
import { useAuth } from './Context/AuthContext';
import { ReactFlowContextProvider } from './Context/reactFlowContext';
import { ModalProvider } from './Context/ModalContext';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import { Routes, Route, useNavigate } from "react-router-dom";
import DesignPage from "./Pages/DesignPage";
import MainPage from './Pages/MainPage';
import ProfileModal from './Components/ProfileModal';
import LoginPage from './Pages/LoginPage';
import NotFoundPage from './Pages/NotFoundPage';
import { SnowFlakeProvider } from './Context/SnowFlakeContext';
import { AlertProvider } from './Context/AlertContext';
import { DesignsProvider } from './Context/DesignsContext';
import { SocketioProvider } from './Context/SocketioContext';
import { PlaygroundContextProvider } from './Context/PlaygroundContext';
import IdleTimer from './Components/IdleTimer';
import { LoadingProvider } from './Context/LoadingContext';
import GlobalLoading from './Components/GlobalLoading';

function App() {
  const navigate = useNavigate()
  const {getUser, user, logout } = useAuth();

  useEffect(() => {
    getUser()
  }, []);
  
  useEffect(() => {
    const timeoutDuration = process.env.REACT_APP_INACTIVITY_TIMER
    const timer = new IdleTimer({
      userSignedIn: user !== null ? true : false,
      disableForLocalEnv: process.env.REACT_APP_INACTIVITY_AUTO_LOGOUT_DISABLED === "TRUE" ? true : false, 
      timeout: timeoutDuration !== undefined ? parseInt(timeoutDuration, 10) : 300,
      onTimeout: () => {
        logout()
        navigate('/')
      },
      onExpired: () => {
        logout()
      }
    })

    return () => {
      timer.cleanUp()
    }
  }, [user, logout, navigate])

  return (
    <div className="App">
      <LoadingProvider>
      <AlertProvider>
      <SocketioProvider>
      <SnowFlakeProvider>
      <ReactFlowContextProvider>
      <DesignsProvider>
      <ModalProvider>
        <GlobalLoading />
        <Routes>
          <Route path="/" element={
            !user ? 
            <LoginPage /> : 
            <MainPage />
          } 
          />
          <Route path="design" 
            element={
              <PlaygroundContextProvider>
                <DesignPage />
              </PlaygroundContextProvider>
            } 
          />
          <Route path="profile" 
            element={
              <ProfileModal />
            } 
          />
          <Route exact path='*'
            element={<NotFoundPage />} 
          />
        </Routes>
      </ModalProvider>
      </DesignsProvider>
      </ReactFlowContextProvider>
      </SnowFlakeProvider>
      </SocketioProvider>
      </AlertProvider>
      </LoadingProvider>
    </div>
  );
}

export default App;
