import React from "react";
import { useLoaderContext } from "../Context/LoadingContext";
import CircularProgress from "@mui/material/CircularProgress";

export default function GlobalLoading() {
  const { isLoading } = useLoaderContext();

  return (
    <>
      {isLoading ? (
        <div className="global-spinner">
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
}
