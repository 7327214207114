import React from 'react';
import Avatar from '@mui/material/Avatar';
import { useAuth } from "../Context/AuthContext"
import {
  Popper,
  ClickAwayListener,
  Paper,
  MenuItem,
  MenuList,
} from '@mui/material';
import { ReactComponent as LogoutIcon } from '../Assets/svg/logoutIcon.svg';

const LogoutDropdown = (props) => {

    const {top} = props;
    const { user, logout } = useAuth()
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };
  
    const clickHandler = () => {
      setOpen(false);
      logout();
    };
  
    return (
      <div>
        <div className="user-profile-content"  onClick={handleToggle}>
          <Avatar alt="avatar pic" src="https://lh3.googleusercontent.com/a/default-user=s96-c" />
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          className="dropdown-container"
          placement='auto'
          transition={true}
          sx={{top:`${top}px!important`}}
        >
          {({ TransitionProps }) => (
            <Paper {...TransitionProps} className="dropdown-content" >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className="menu-content" autoFocusItem={false} id="menu-list-grow" >
                  <MenuItem key="logout" onClick={clickHandler} sx={{ fontFamily: 'RocheRegular', width: '130px' }}>
                    <LogoutIcon style={{marginRight:'10px'}}/>
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          )}
        </Popper>
      </div>
    )
  }

  export default LogoutDropdown;