import React from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Radio from "@mui/material/Radio";
import ExpandableText from "./ExpandableText";

const ColumnSettingsOptions = ({
  items,
  selectOption,
  deleteOption,
  addOption,
  isLoading,
  authorized,
}) => {
  const handleOnKeyDown = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    addOption(e.target.value);
    e.target.value = "";
  };

  return (
    <>
      {items.map((item) => (
        <div key={item.id} className="column-settings-option">
          <Radio
            disabled={isLoading || !authorized}
            checked={item.selected}
            onChange={() => selectOption(item.id)}
          />
          <div className="column-settings-option-data">
            <ExpandableText item={item} deleteOption={deleteOption} />
          </div>
        </div>
      ))}
      <TextareaAutosize
        disabled={isLoading || !authorized}
        onKeyDown={handleOnKeyDown}
        className="column-settings-textarea"
      />
      <p className="column-settings-textarea-helper-text">
        Type your own option and confirm with enter
      </p>
    </>
  );
};

export default ColumnSettingsOptions;
