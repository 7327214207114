import { useState } from 'react';

const customStyle = {
  border: 'none',
  backgroundColor: 'transparent',
  color: '#fff',
}

const TransparentTextField = ({ inputText, updateTextHandler, authorized }) => {
  const [value, setValue] = useState(inputText)

  return (
    <input 
      type="text"
      data-testid="transparent-text-field"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => updateTextHandler(value)}
      style={customStyle}
      disabled={!authorized}
    />
  )
}

export default TransparentTextField;