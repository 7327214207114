export const fileNameList = [
    "{your_abbreviation}_{object_name}.sql",
    "{your_abbreviation}_{object_name}.txt",
    "config_{your_abbreviation}_object_schema.sql",
    "config_{your_abbreviation}_object_schema.txt",
    "CONFIG_DATA_TYPES.sql",
    "CONFIG_DATA_TYPES.txt",
    "CONFIG_PROCEDURE_STATUS.sql",
    "CONFIG_PROCEDURE_STATUS.txt",
]

export const dva_version_with_mandate_origfields = [
    "version_1.2.0",
    "version_1.3.0",
    "version_1.3.2",
    "version_1.3.7"
]
