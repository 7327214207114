import Form from "react-bootstrap/Form";
import React, { useState, useEffect, useRef } from "react";
import { useDesignsContext } from "../Context/DesignsContext";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  BUSINESS_KEY,
  DRIVING_KEY,
  SELECTED_COLUMNS,
  SKIP_IN_SYSHASH,
  SYS_SEQUENCER,
} from "./ReactFlowRenderer/CustomNodes/NodeLinkTypes";

const CustomCheckboxList = ({
  inputGroupName,
  checkboxList,
  filterable,
  handleCheckboxListChange,
  updateListOrder,
  isSortable,
  title,
}) => {
  const [filterInput, setFilterInput] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([...checkboxList]);
  const { currentDesign } = useDesignsContext();
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const filterList = (event) => {
    setFilterInput(event.target.value);
  };

  useEffect(() => {
    if (filterInput !== "") {
      const newCheckboxOptions = checkboxList.filter((listItem) => {
        if (
          listItem.columnItem.toUpperCase().includes(filterInput.toUpperCase())
        ) {
          return listItem;
        }
      });
      setFilteredOptions([...newCheckboxOptions]);
    } else {
      setFilteredOptions([...checkboxList]);
    }
  }, [filterInput, checkboxList]);

  useEffect(() => {
    setFilterInput("");
  }, [checkboxList]);

  const handleChange = (event) => {
    const checkedItemLabel = event.target.id.split(`${inputGroupName}-`)[1];
    const checkedItemValue = event.target.checked;
    handleCheckboxListChange(
      checkedItemLabel,
      checkedItemValue,
      inputGroupName
    );
  };

  const handleOrderChange = () => {
    let duplicateList = [...checkboxList];
    const dragItemContent = duplicateList.splice(dragItem.current, 1)[0];
    duplicateList.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    if (
      inputGroupName === BUSINESS_KEY ||
      inputGroupName === SKIP_IN_SYSHASH ||
      inputGroupName === SYS_SEQUENCER ||
      inputGroupName === DRIVING_KEY ||
      inputGroupName === SELECTED_COLUMNS
    )
      updateListOrder(duplicateList);
    else {
      updateListOrder((prevState) => {
        const object = { ...prevState };
        object[inputGroupName] = duplicateList;
        return { ...object };
      });
    }
  };

  return (
    <Form className="pb-4" onSubmit={(event) => event.preventDefault()}>
      {filterable && (
        <Form.Control
          type="search"
          placeholder="Search..."
          aria-label="Search"
          onChange={filterList}
          value={filterInput}
        />
      )}
      <div className="custom-checkbox px-2">
        {title !== "" ? <p className="custom-checkbox-title">{title}</p> : null}
        {filteredOptions.map((item, itemIndex) => {
          return isSortable ? (
            <div
              draggable={item.checked}
              droppable={item.checked}
              onDragStart={() => (dragItem.current = itemIndex)}
              onDragEnter={() =>
                item.checked
                  ? (dragOverItem.current = itemIndex)
                  : (dragOverItem.current = dragItem.current)
              }
              onDragOver={(e) => e.preventDefault()}
              onDragEnd={handleOrderChange}
              style={{ cursor: item.checked ? "move" : "pointer" }}
            >
              {item.checked && (
                <DragIndicatorIcon color="disabled" className="pb-1" />
              )}
              <Form.Check
                key={item}
                type="checkbox"
                id={`${inputGroupName}-${item.columnItem}`}
                label={`${item.columnItem}`}
                onChange={handleChange}
                checked={item.checked}
                disabled={!currentDesign.authorized}
                className={
                  item.checked ? "d-inline-block checkedListItem" : "ms-4 ps-4"
                }
              />
            </div>
          ) : (
            <Form.Check
              key={item}
              type="checkbox"
              id={`${inputGroupName}-${item.columnItem}`}
              label={`${item.columnItem}`}
              onChange={handleChange}
              checked={item.checked}
              className={"ms-4 ps-4"}
              disabled={!currentDesign.authorized}
            />
          );
        })}
      </div>
    </Form>
  );
};

export default React.memo(CustomCheckboxList);
