import React, { useEffect, useState, useCallback } from "react";
import CustomDropdown from "./CustomDropdown";
import NodeDragDrop from "./NodeDragDrop";
import { useSnowFlakeContext } from "../Context/SnowFlakeContext";
import { useAuth } from "../Context/AuthContext";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
import { useSocketioContext } from "../Context/SocketioContext";
import Search from "./Search";

// const databaseList = Object.keys(Warehouse);

const DropdownSideBar = ({
  menuCollapse,
  handleMenuCollapse,
  reactFlowWrapper,
}) => {
  const {
    database,
    schema,
    table,
    nodeLink,
    databaseList,
    schemaList,
    tableList,
    nodeLinkList,
    setDatabaseState,
  } = useSnowFlakeContext();
  const { socket } = useSocketioContext();
  const { snowflakeAccount, accountsList } = useAuth();
  const [filteredSchemaList, setFilteredSchemaList] = useState([]);

  useEffect(() => {
    if (socket !== null) {
      //Socket event 'getDatabases' requested from client to get the databases list from server
      socket?.emit("getDatabases", {
        account: accountsList[snowflakeAccount]?.accountURL,
      });
    }

    if (database) {
      socket?.emit("getSchemas", {
        database,
        account: accountsList[snowflakeAccount]?.accountURL,
      });
    }
  }, [socket]);

  useEffect(() => {
    const filterPattern =
      accountsList[snowflakeAccount]?.sourceSchemaFilter || "";
    const schemaFilter = new RegExp(`${filterPattern}`, "i");
    const filteredSchemas = schemaList.filter((schema) =>
      schemaFilter.test(schema)
    );
    setFilteredSchemaList(filteredSchemas);
  }, [schemaList]);

  const handleDatabase = useCallback((value) => {
    setDatabaseState((prevState) => {
      return {
        ...prevState,
        database: value,
        schema: "",
        table: "",
        nodeLink: "",
      };
    });
  }, []);

  const handleSchema = useCallback((value) => {
    setDatabaseState((prevState) => {
      return {
        ...prevState,
        schema: value,
        table: "",
        nodeLink: "",
      };
    });
  }, []);

  const handleTable = useCallback((value) => {
    setDatabaseState((prevState) => {
      return {
        ...prevState,
        table: value,
        nodeLink: "",
      };
    });
  }, []);

  const handleNodeLink = useCallback((value) => {
    setDatabaseState((prevState) => {
      return {
        ...prevState,
        nodeLink: value,
      };
    });
  }, []);

  return (
    <div className="container d-flex flex-column h-100">
      <div className="d-flex flex-column justify-content-between">
        <div className="row px-0">
          <div className="col align-self-start px-0">
            <div className="my-2 w-75 mx-auto">
              <CustomDropdown
                dropdownList={databaseList}
                dropdownName="Database"
                handleSelection={handleDatabase}
                selectedValue={database}
                filterable={true}
              />
            </div>
            <div className="my-2 w-75 mx-auto">
              <CustomDropdown
                dropdownList={filteredSchemaList}
                dropdownName="Schema"
                handleSelection={handleSchema}
                selectedValue={schema}
                filterable={true}
              />
            </div>
            <div className="my-2 w-75 mx-auto">
              <CustomDropdown
                dropdownList={tableList}
                dropdownName="Table"
                handleSelection={handleTable}
                selectedValue={table}
                filterable={true}
              />
            </div>
            <div className="mt-4 mb-2 w-75 mx-auto">
              <CustomDropdown
                dropdownList={nodeLinkList}
                dropdownName="Node"
                handleSelection={handleNodeLink}
                selectedValue={nodeLink}
                filterable={false}
              />
            </div>
            <div className="my-2 w-75 mx-auto">
              <NodeDragDrop
                tableDetails={{ database, schema, table, nodeLink }}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <Search reactFlowWrapper={reactFlowWrapper} />
    </div>
  );
};

export default DropdownSideBar;
