import React, { useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { faqs, introduction } from '../Constant/FAQPage'
import AccordionDetailsWithLinks from './AccordionDetailsWithLinks';
import AccordionDetailsWithButton from './AccordionDetailsWithButton';

const AccordianList = () => {
    const [expanded, setExpanded] = useState(null);

    const handleChange = (accordionData) => (event, isExpanded) => {
        setExpanded(isExpanded ? accordionData : null);
    };
    const handleJump = (question) => {
        const questionExists = faqs.find(q => q.question === question)
        setExpanded(questionExists);
        const accordian = document.getElementById(question);
        accordian.scrollIntoView({ behavior: 'smooth' })
    };

    return (
        <div>
            <p className="intro pb-3">{introduction.firstLine}
                {introduction.bulletList.map((item, index) => (
                    <li className="intro" id={index} >
                        {item}
                    </li>
                ))}
                {introduction.lastLine}</p>
            {faqs.map((accordionData) => (
                <Accordion id={accordionData.question}
                    key={accordionData.question}
                    expanded={expanded === accordionData}
                    onChange={handleChange(accordionData)}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div className="accordianQuestion"
                            style={{ color: expanded === accordionData ? 'blue' : 'black' }}
                        >{accordionData.question}
                        </div>
                    </AccordionSummary>
                    {!accordionData.isDVAUse &&
                        <AccordionDetailsWithLinks
                            accordionData={accordionData}
                            onJump={handleJump}
                        />}
                    {accordionData.isDVAUse &&
                        <AccordionDetailsWithButton
                            accordionData={accordionData}
                        />}
                </Accordion>
            ))}
        </div>
    )
}




export default AccordianList