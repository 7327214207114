import { useEffect, useState } from 'react'
import DocumentationPage from '../Pages/DocumentationPage'
import Form from "react-bootstrap/Form";
import { TUTORIALS, HOME, BACKLOG } from '../Types/MenuTypes'
import { useDesignsContext } from '../Context/DesignsContext'
import { useSocketioContext } from '../Context/SocketioContext'
import BacklogPage from '../Pages/BacklogPage'
import FilterSelectDropdown from './FilterSelectDropdown';
import DatabaseContent from './DatabaseContent';
import DesignsContent from './DesignsContent';
import { useAuth } from "../Context/AuthContext";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import PaginationComponent from './PaginationComponent';
import Typography from '@mui/material/Typography';
import MainContentTop from './MainContentTop';
import MainContentTopSearch from './MainContentTopSearch'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SimmerUI from './SimmerUI';

const MainContent = ({ selectedMenu }) => {
  const { openDesign, sharedDesignObject } = useDesignsContext()
  const { socket } = useSocketioContext()
  const [home, setHome] = useState([])
  const [recentDesign, setResentDesign] = useState([])
  const [DBdata, setDBdata] = useState([])
  const [previousMenu, setPreviousMenu] = useState('')
  const [account, setAccount] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [selectedValue, setSelectedValue] = useState("newest");
  const [currentPage, setCurrentPage] = useState();
  const [toggleData, setToggleData] = useState("Designs");
  const { user, setSnowflakeAccount, getAccountNameByURL } = useAuth();
  const usernameValue = user?.attributes?.name;
  const [limit, setLimit] = useState();
  const [designDB, setDesignDB] = useState(false);
  const [currentDB, setCurrentDB] = useState();
  const [searchResult, setSearchResult] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([{
    text: "Explore databases in DVA",
    link: "1"
  }
  ]);

  useEffect(() => {
    if (selectedMenu !== previousMenu && selectedMenu === HOME) {
      setLoading(true);
      socket?.emit("getAccount", { userId: usernameValue }, ({ account }) => {
        if (account) {
          setAccount(account);
          const accountName = getAccountNameByURL(account)
          setSnowflakeAccount(accountName);
          if (toggleData !== "Database") {
            getRecentData({ account });
          }
          setPreviousMenu(selectedMenu);
          setCurrentPage(1);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu, socket, usernameValue, account])
  useEffect(() => {
    if (socket !== null) {
      //Socket event 'receiveRecentAccessedDesigns' received from server with recent designs data in payload
      socket.on('receiveRecentAccessedDesigns', (design) => {
        setResentDesign(design.data);
        if (design.data.length > 0) {
          setLimit(3);
        } else {
          setLimit(6);
        }
        setLoading(false);
      })
    }
  }, [socket])
  useEffect(() => {
    if (account !== "") {
      setCurrentPage(1);
      setSelectedValue("newest");
      setSearchResult("");
      getData(account, 1, limit, selectedValue);
    }
  }, [account])
  useEffect(() => {
    if (account !== "") {
      getData(account, currentPage, limit, selectedValue);
    }
  }, [limit])
  useEffect(() => {
    if (socket !== null) {
      //Socket event 'receiveSharedDesigns' received from server with public designs data in payload
      socket.on('receiveSharedDesigns', (data) => {
        setHome(data.designs);
        setTotalPages(data.pageCount);
        setLoading(false);
      })
    }
  }, [socket, home, totalPages])

  useEffect(() => {
    if (socket !== null) {
      //Socket event 'receiveAllDatabaseWithDesignCount' received from server with public database data in payload
      socket.on('receiveAllDatabaseWithDesignCount', (database) => {
        setDBdata(database.data);
        setTotalPages(database.pageCount)
        setLoading(false);
      })
    }
  }, [socket, toggleData])

  useEffect(() => {
    if (account !== "" && toggleData === "Database") {
      setLimit(6);
      setCurrentPage(1);
      if (recentDesign?.length === 0) {
        getData(account, currentPage, limit, selectedValue);
      }
    } else if (account !== "" && toggleData === "Designs") {
      recentDesign?.length > 0 ? setLimit(3) : setLimit(6);
      setCurrentPage(1);
    }
  }, [socket, toggleData])
 
  const handleToggleChange = (e) => {
    setToggleData(e.target.value);
    if (breadcrumbs.length > 1) {
      breadcrumbs.pop();
    }

  }
  const handleBreadcrumb = (e) => {
    if (breadcrumbs.length > 1) {
      setCurrentDB("");
      setDesignDB(false);
      setCurrentPage(1);
      breadcrumbs.pop();
      const requestPayload = {
        account: account,
        database: "",
        latest: (selectedValue === "newest") ? true : false,
        limit: limit,  // number to be kept so design doesn't overflow
        currentPage: currentPage,
        searchFilter: searchResult?.toUpperCase(),
      }
      socket.emit('getAllDatabaseWithDesignCount', requestPayload);
    }
  }
  const getRecentData = (account) => {
    socket?.emit('getRecentAccessedDesigns', account);
  }
  const getData = (account, currentPage, limit, selectedValue, dbData, searchValue) => {
    setCurrentDB(dbData);
    setLoading(true);
    if (toggleData === "Database") {
      if (breadcrumbs.length < 2 && dbData?.DATABASE != null && dbData?.DATABASE != "") {
        setCurrentPage(1);
        setBreadcrumbs([...breadcrumbs, { text: dbData?.DATABASE }]);
      }

      if (socket !== null && dbData?.DATABASE) {
        const requestPayloadDbDesign = {
          account: account,
          database: dbData?.DATABASE,
          latest: (selectedValue === "newest") ? true : false,
          limit: limit,  // number to be kept so design doesn't overflow
          currentPage: currentPage,
          searchFilter: searchValue?.toUpperCase(),
        }
        setDesignDB(true);
        socket?.emit('getSharedDesigns', requestPayloadDbDesign);
      } else {
        const requestPayloadDb = {
          account: account,
          database: dbData?.DATABASE,
          latest: (selectedValue === "newest") ? true : false,
          limit: limit,  // number to be kept so design doesn't overflow
          currentPage: currentPage,
          searchFilter: searchValue?.toUpperCase(),
        }
        setDesignDB(false);
        socket.emit('getAllDatabaseWithDesignCount', requestPayloadDb);
        if (breadcrumbs.length >= 2) {
          breadcrumbs.pop();
        }
      }
    } else {
      const requestPayloadDesign = {
        account: account,
        database: "", //optional based on requirement
        latest: (selectedValue === "newest") ? true : false,
        limit: limit,  // number to be kept so design doesn't overflow
        currentPage: currentPage,
        searchFilter: searchValue?.toUpperCase(),
      }
      socket?.emit('getSharedDesigns', requestPayloadDesign);
    }
  }
  const mapData = (data) => {

    return (
      <>

        <MainContentTop account={account} setAccount={setAccount} getRecentData={getRecentData} recentDesign={recentDesign} toggleData={toggleData} />
        <div className='dataWrapper mt-3'>
          {toggleData === 'Designs' ? <div className="card-headline" color="text.primary">Explore other designs in DVA</div>
            : <div className='custom-breadcrumb'>
              {breadcrumbs.map(breadcrumb => {
                return (
                  (breadcrumb?.link) ? <div className="card-headline" color="text.primary" onClick={handleBreadcrumb}>{breadcrumb.text}</div> :
                    <><NavigateNextIcon fontSize="small" /><Typography color="primary">{breadcrumb.text}</Typography></>
                )
              })}

            </div>}
            
          <div className='filter_wrapper'>
          <MainContentTopSearch searchResult ={searchResult} setSearchResult={setSearchResult} account={account} getData={getData} currentPage={currentPage} setCurrentPage={setCurrentPage} limit={limit} currentDB={currentDB} selectedValue={selectedValue}/>
            <FilterSelectDropdown account={account} totalPages={totalPages} getData={getData} currentPage={currentPage} limit={limit} selectedValue={selectedValue} setSelectedValue={setSelectedValue} currentDB={currentDB} searchResult ={searchResult}></FilterSelectDropdown>

            <ButtonGroup variant="outlined" aria-label="button group">
              <Button className={toggleData === 'Database' ? "primary-button-color" : "secondary-button-color"} onClick={handleToggleChange} value="Database">Database</Button>
              <Button className={toggleData === 'Designs' ? "primary-button-color" : "secondary-button-color"} onClick={handleToggleChange} value="Designs">Designs</Button>
            </ButtonGroup>

          </div>
        </div>

        <div className="card_wrappers">
          {loading ? <><SimmerUI />
            {limit === 6 ? <SimmerUI /> : ""}</> : toggleData === "Designs" ?
            <DesignsContent data={home} recentDesign={recentDesign} toggleData={toggleData} />
            : designDB ? <DesignsContent data={home} recentDesign={recentDesign} toggleData={toggleData} /> : <DatabaseContent id="1" database={DBdata} account={account} totalPages={totalPages} getData={getData} currentPage={currentPage} setCurrentPage={setCurrentPage} limit={limit} selectedValue={selectedValue} />}
        </div>
        {totalPages > 1 ? <PaginationComponent account={account} totalPages={totalPages} getData={getData} currentPage={currentPage} setCurrentPage={setCurrentPage} limit={limit} selectedValue={selectedValue} database={currentDB} searchResult ={searchResult}/> : ""}


      </>
    )
  }

  return (
    <div id="main-content-cards">
      {selectedMenu === TUTORIALS && (
        <DocumentationPage />
      )}
      {selectedMenu === BACKLOG && (
        <BacklogPage />
      )}
      {selectedMenu === HOME && (
        mapData(home)
      )}
    </div>
  )
}

export default MainContent;