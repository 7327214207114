import Alert from 'react-bootstrap/Alert';
import { useAlertContext } from '../Context/AlertContext'

const CustomAlert = () => {
  const { alertsList, removeAlert, alertTypes } = useAlertContext()

  if(alertsList.length > 0) {
    const activeAlert = alertsList[alertsList.length - 1]
    const alertVariant = activeAlert.variant || alertTypes['CLIENT_ERRORS']
    if (activeAlert.isDismissible) {
      return (
        <div className="m-2 customAlert">
          <Alert variant={alertVariant} onClose={removeAlert} dismissible>
            <p className="mb-0">{activeAlert.msg}</p>
            {activeAlert.isIncident ? (
              <div>
                Please raise an incident here: <a href="https://roche.service-now.com/rose?id=nr_sc_cat_item&sys_id=f1bf99f91bb2a090f9be99baab4bcb74" alt="incident link">https://roche.service-now.com/rose?id=nr_sc_cat_item&sys_id=f1bf99f91bb2a090f9be99baab4bcb74</a>
              </div>
            ) : null}
            {activeAlert.isTicketIssue ? (
              <div>
                If this should not be the case please raise a ticket here: <a href="https://roche.service-now.com/rose?id=nr_sc_cat_item&sys_id=a74da5b11bbea090f9be99baab4bcb1b" alt="incident link">https://roche.service-now.com/rose?id=nr_sc_cat_item&sys_id=a74da5b11bbea090f9be99baab4bcb1b</a>
              </div>
            ) : null}
          </Alert>
        </div>
      )
    } else {
      return (
        <div className="m-2 errorAlert">
          <Alert variant={alertVariant} className='p-0 m-0'>
            <p className='m-0 text-center'>{activeAlert.msg}</p>
          </Alert>
        </div>
      )
    }
  } else return null
}

export default CustomAlert;