import React, { useState } from "react";
import Form from "react-bootstrap/Form";

import { useAuth } from "../Context/AuthContext";
import { useSocketioContext } from "../Context/SocketioContext";
import { useSnowFlakeContext } from "../Context/SnowFlakeContext";

const AccountSelectDropdown = ({ account, setAccount, setLoader, getRecentData, toggleData }) => {

  const { user, snowflakeAccount, setSnowflakeAccount, accountsList } = useAuth();
  const { socket } = useSocketioContext();
  useSnowFlakeContext();
  const usernameValue = user?.attributes?.name;

  const [loading, setLoading] = useState(false);

  const handleChange = async (event) => {
    const accountName = event.target.value
    const accountURL = accountsList[accountName]?.accountURL || accountsList["ROCHE_DIA"]?.accountURL
    setLoading(true);
    setLoader(true);
    setAccount(accountsList[accountName]?.accountURL || "");
    setSnowflakeAccount(accountName);

    try {
      await new Promise((resolve, reject) => {
        socket.emit(
          "setAccount",
          { account: accountURL, userId: usernameValue },
          (resolvePayload) => {
            if (resolvePayload.success) {
              resolve(true);
            } else {
              reject(false);
            }
          }
        );
        if (socket !== null && toggleData==="Designs") {
          getRecentData({account:accountsList[accountName]?.accountURL});
        }
      });
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="col col-xs-12">
      <Form.Group className="account_select_dropdown" controlId="formAccount">
        <Form.Select
          defaultValue=""
          value={snowflakeAccount}
          aria-label="Select Account"
          onChange={handleChange}
          disabled={loading}
          style={{"fontSize":"14px"}}
        >
          <option value="" disabled>
            Select Account
          </option>
          {
            Object.keys(accountsList).map((accountName) => {
              return (
                <option key={accountName} value={accountName}>{accountName.toString()}</option>
              )
            })
          }
          {/* <option value="" disabled>
            Select Account
          </option>
          <option value="roche_dia.eu-central-1">ROCHE_DIA</option>
          <option value="roche_dia_namlocal.us-east-2.aws">NAM LOCAL</option> 
          <option value="roche-dia-chlocal.switzerland-north.azure">
            CHLOCAL
          </option> */}
        </Form.Select>
      </Form.Group>
    </div>
  );
};

export default AccountSelectDropdown;
