import * as React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from '@mui/material/Typography';
import { ReactComponent as UpArrow } from "../Assets/svg/upArrow.svg";
import { ReactComponent as DownArrow } from "../Assets/svg/downArrow.svg";
import { useSocketioContext } from '../Context/SocketioContext';
import { useSnowFlakeContext } from "../Context/SnowFlakeContext";
import CustomRadio from "./CustomRadio";

const CustomVersionDropdown = () => {
  const [isActive, setIsActive] = React.useState(false);
  const [versionList, setVersionList] = React.useState([]);
  const { dvaVersion, setDvaVersion } = useSnowFlakeContext();
  const { socket } = useSocketioContext();

  function handleClick() {
    setIsActive(!isActive)
  }

  React.useEffect(() => {
    if (socket) {

      socket.emit('getGeneratorVersions', null, (receivedVersionList) => {
        setVersionList(receivedVersionList);
        if (receivedVersionList.length > 0 && dvaVersion === "") {
          setDvaVersion(receivedVersionList[0]);
        }
      });
    }
  }, [socket]);

  const handleVersionChange = (event) => {
    setDvaVersion(event.target.value);
  };

  return (
    <div className="version-dropdown">
      <div className="version-dropdown-btn" onClick={handleClick}>
        <Typography className="version-dropdown">Version: DVA {dvaVersion.slice(8)}
          {isActive ? <UpArrow className="mx-4" /> : <DownArrow className="mx-4" />}
        </Typography>
      </div>
      <div className="version-dropdown-content">
        {isActive && (
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={dvaVersion}
            onChange={handleVersionChange}
          >
            {versionList.map((version) => (

              <FormControlLabel key={version} value={version} 
                control={<CustomRadio/>}
                label={<Typography className="version-dropdown-content">DVA {version.slice(8)}</Typography>} />)
            )}
          </RadioGroup>
        )}
      </div>
    </div>
  )
};

export default CustomVersionDropdown;
