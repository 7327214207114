import {
    Link,
    List,
    ListItem,
    ListItemText,
    AccordionDetails,
    Typography
} from '@mui/material';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';


const AccordionDetailsWithLinks = ({ accordionData, onJump }) => {

    const { content1,isIcon, link1, content2, link2, list, para, jumpLink } = accordionData;

    return (

        <AccordionDetails>
            <Typography>
                <div className='fontMatch'>{content1 && content1} {link1 && (<><Link  target="_blank" href={link1.url}>{link1.text}</Link></>)}
                    {jumpLink && (<><Link onClick={() => onJump(jumpLink.jumpQuestion)}>{jumpLink.text}</Link></>)}
                    {list && (<>
                        <List sx={{ pl: 2, listStyleType: 'disc' }}>
                            {list.map((item, index) => (
                                <ListItem key={index}  sx={{ display: 'list-item' }}>
                                    <ListItemText className='fontMatch' ><Typography>
                                        {item.text}
                                        {item.link && (<><Link target="_blank" href={item.link.url}>{item.link.text}</Link></>)}
                                    </Typography>
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List></>)}
                {isIcon && (<><AutoAwesomeMotionIcon sx={{ fontSize: 20, color: '#f19d32' }} /></>)}{content2 && content2} {link2 && (<><Link target="_blank" href={link2.url}>{link2.text}</Link></>)}
                {para && para.map((text, index) => (<><p className='fontMatch'>{text}</p></>))}
                </div>
            </Typography>
        </AccordionDetails>
    )
}

export default AccordionDetailsWithLinks