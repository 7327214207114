import React from 'react'

const VideoItem = ({title ,videoUrl})=>(
    <div>
        <h3>{title}</h3>
        <video width="424" height="244" controls>
            <source src={videoUrl} type="video/mp4" />
            your browser does not support the video tag
        </video>
        </div>
);

const VideoList = ({videos})=>(
    <div>
        {videos.map((video, index)=>(
            <VideoItem key={index} title={video.title} videoUrl={video.videoUrl} />
        ))}
    </div>
);

export default VideoList;