import { useModalContext } from '../../../Context/ModalContext';
import { useState, useEffect } from 'react';
import './CustomNodesStyle.css';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  DATA,
  USER_SETTINGS,
  TABLE_ABBREVIATION,
  DRAGGED_CONNECTION,
  CONNECTION_TYPE,
  BUSINESS_KEY,
  SOURCE_DELETED_FIELD_INDICATOR,
  SOURCE_DELETED_FIELD_NAME,
  TALEND_DELETED_FIELD_INDICATOR,
  SELECTED_COLUMNS,
} from './NodeLinkTypes';
import { usePlaygroundContext } from '../../../Context/PlaygroundContext';

const XRefNode = (node) => {
  const { enableNodeSettingsModalShow, setNodeSettingsModalCurrentNode } = useModalContext()
  const { onElementsRemove } = usePlaygroundContext()
  const { label } = node[DATA]
  const [warning, setWarning] = useState(false)

  const onDelete = () => {
    onElementsRemove([node])
  }

  useEffect(() => {
    if(node[DATA][USER_SETTINGS] !== undefined) {
      switch (true) {
        case (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === undefined):
        case (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === ''):
        case (node[DATA][USER_SETTINGS][BUSINESS_KEY].length < 1 && !(node[DATA][DRAGGED_CONNECTION][CONNECTION_TYPE] === "HUB||SAT||LINK||LSAT")):
        case (node[DATA][USER_SETTINGS][SELECTED_COLUMNS] === undefined || node[DATA][USER_SETTINGS][SELECTED_COLUMNS].length < 1):
        case (node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === undefined):
        case (node[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR] === undefined):
        case (node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true && node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME] === ''):
          setWarning(true)
          break
        default:
          setWarning(false)
          break;
      }
      if(!(node[DATA][USER_SETTINGS].hasOwnProperty(SELECTED_COLUMNS))){
        if((node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] !== undefined) && (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] !== '')){
          setWarning(false);
        }
      }
    } else {
      setWarning(true)
    }
  }, [node[DATA][USER_SETTINGS]])

  const onClick = () => {
    setNodeSettingsModalCurrentNode(node)
    enableNodeSettingsModalShow(node.id)
  }

  return (
    <div className="xrefNode">
      <div className="py-1 px-2" >
        {label}
        <span className="position-absolute top-0 start-100 translate-middle badge fs-6" hidden={!warning}>
        <Tooltip 
          title="Node Settings incomplete." 
          placement="bottom-start" 
          arrow
        >
          <ErrorTwoToneIcon sx={{ fontSize: 25, color: 'red' }} />
        </Tooltip>
        </span>
        <span className="ms-2" onClick={onClick} style={{ cursor: 'pointer' }}>
          <Tooltip 
            title="Node settings" 
            placement="bottom-end" 
            arrow 
          >
            <SettingsIcon style={{ fontSize: 'large', marginBottom: "0.175rem" }} />
          </Tooltip>
        </span>
        {node.selected && (
          <span className="ms-2" onClick={onDelete} style={{ cursor: 'pointer' }}>
            <Tooltip 
              title="Delete node along with its dependents" 
              placement="bottom-start" 
              arrow 
            >
              <DeleteForeverIcon style={{ fontSize: 'large', marginBottom: "0.175rem" }} />
            </Tooltip>
          </span>
          )
        }
      </div>
    </div>
  );
}

export default XRefNode;
