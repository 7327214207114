import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button';

const NotFoundPage = () => {
  const navigate = useNavigate()
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '89vh' }}>
      <div>
        <TravelExploreRoundedIcon sx={{fontSize: 70, pb: '1rem'}} /><span className='fs-1'>Page not Found!!!</span>
        <br />
        <div className="w-100 text-center">
          <Button size="lg" onClick={() => navigate('/')}>Go to Home Page</Button>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage