import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useAuth } from '../Context/AuthContext';
import { useModalContext } from '../Context/ModalContext';

const ProfileModal = () => {
  const { user, logout } = useAuth()
  const { profileModal, profileModalHide } = useModalContext()
  const userName = user !== undefined ? user?.attributes?.name : 'User'

  const handleSignOut = event => {
    event.preventDefault();
    profileModalHide();
    logout();
  }

  return (
    <Modal
      show={profileModal}
      onHide={profileModalHide}
      size="sm"
      aria-labelledby="profile-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="profile-modal">
          Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify content-center">
          <img className="profile-avatar mx-auto" src="https://lh3.googleusercontent.com/a/default-user=s96-c" alt="profile avatar" />
        </div>
        <p className="text-center mt-1">{userName}</p>
        <hr />
        <div className="my-2 d-grid">
          <Button onClick={handleSignOut}>Sign Out</Button>
        </div>
        {/* <div className="text-center mt-4 profile-options">
          <hr className="m-0"/>
          <hr className="m-0"/>
          <h3 onClick={signOut}>Sign Out</h3>
        </div> */}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={profileModalHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default ProfileModal;