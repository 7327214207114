import React, { useEffect, useState } from 'react';
import PlayGround from "../Components/ReactFlowRenderer/PlayGround";
import ActionBar from "../Components/ActionBar";
import VerificationPage from './VerificationPage';
import ProceduresPage from './ProceduresPage';
import classNames from "classnames";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { isNode } from 'react-flow-renderer';
import { useReactFlowContext } from '../Context/reactFlowContext';
import { useSnowFlakeContext } from '../Context/SnowFlakeContext';
import CustomAlert from '../Components/CustomAlert';
import { useAlertContext } from '../Context/AlertContext';
import { useLoaderContext } from '../Context/LoadingContext';
import { usePlaygroundContext } from '../Context/PlaygroundContext';
import {
  DATA,
  LABEL,
  TABLE_ABBREVIATION,
  BUSINESS_KEY,
  SOURCE_DELETED_FIELD_INDICATOR,
  SOURCE_DELETED_FIELD_NAME,
  TALEND_DELETED_FIELD_INDICATOR,
  SATELLITE_UPDATED_LABEL,
  USER_SETTINGS,
  DRIVING_KEY,
  DRIVING_KEY_LABEL,
  DRAGGED_CONNECTION,
  CONNECTION_TYPE,
  OVERLOAD_SOURCE_TABLES_OBJECT,
  XLINK_NAME_UPDATED_LABEL
} from '../Components/ReactFlowRenderer/CustomNodes/NodeLinkTypes'
import { useDesignsContext } from '../Context/DesignsContext';
import { useNavigate } from 'react-router-dom';
import { useSocketioContext } from '../Context/SocketioContext';
import { USER_TUTORIAL } from '../Types/MenuTypes';
import { useTranslation } from 'react-i18next';

const steps = ['Create Conceptual Model', 'Verify Details', 'Download'];

const DesignPage = () => {
  const { currentDesign, setCurrentDesignActiveUsers, sharedDesignObject } = useDesignsContext()
  const { t } = useTranslation()

  useEffect(() => {
    sessionStorage.setItem('isSandbox', currentDesign.designName === USER_TUTORIAL)
  }, [currentDesign.designName])

  const navigate = useNavigate()
  const { getElements } = useReactFlowContext()
  const { addAlert } = useAlertContext()
  const [activeStep, setActiveStep] = useState(0)
  const { destinationSchema, destinationDataBase } = useSnowFlakeContext()
  const { socket } = useSocketioContext()
  const { selectedElements } = usePlaygroundContext()
  const [DDLPrefix, setDDLPrefix] = useState('')
  const { isLoading, setIsLoading } = useLoaderContext();
  const [procedurePrefix, setProcedurePrefix] = useState('')
  const [origFieldInclude, setOrigFieldInclude] = useState(true);

  useEffect(() => {
    if (currentDesign.designName === 'Untitled') navigate('/')
    if (socket !== null && socket !== undefined && currentDesign.designId !== null && currentDesign.designId !== USER_TUTORIAL) {
      socket.emit('join_room', {
        room: currentDesign.designId,
        sharedDesignObject
      }, async (activeUsersList) => {
        setCurrentDesignActiveUsers([...activeUsersList])
      })

      return () => {
        socket.emit('leave_room', {
          room: currentDesign.designId
        })
        setCurrentDesignActiveUsers([])
      }
    }
  }, [])

  const playgroundNext = () => {
    let validNodes = true;
    const elementsList = getElements()
    const validNames = []
    if (elementsList.length > 0) {
      try {
        // break the for loop at first encountered error
        elementsList.forEach(element => {
          if (isNode(element)) {
            if (validNames.includes(element[DATA][LABEL])) {
              throw { "type": 'multiple names', "element": element[DATA][LABEL] };
            } else {
              validNames.push(element[DATA][LABEL])
            }

            //  LINKS
            if (element.type === 'linknode' && element.data.linkStatus === true) throw 'Invalid link connections';
            if (((element.type === 'xlinknodedelta') || (element.type === 'xlinknodefull')) && element.data.linkStatus === true) throw 'Invalid xlink connections';

            // Settings modal was not opened
            switch (true) {
              case (element.type === 'hubnode') || (element.type === 'refnode') || (element.type === 'linknode'):
                break;
              case (element[DATA][USER_SETTINGS] === undefined):
                throw { "type": 'Table settings not set', "element": element[DATA][LABEL] };
            }

            // Overloaded
            if (element[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] !== undefined && Object.keys(element[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).length > 0) {
              if (element[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] === '') {
                throw { "type": 'Overloaded satellite rename', "element": element[DATA][LABEL] };
              }
              Object.keys(element[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).forEach(loadedTable => {
                if (element[DATA][OVERLOAD_SOURCE_TABLES_OBJECT][loadedTable] === '') {
                  throw { "type": 'Overloaded satellite source table object', "element": element[DATA][LABEL] };
                }
              })
              const overloadedValues = Object.values(element[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]);
              const overloadedSet = new Set(overloadedValues);

              if (overloadedSet.size !== overloadedValues.length) {
                throw { "type": 'OVERLOADED_SAME_SOURCE', "element": element[DATA][LABEL] };
              }

              overloadedValues.forEach(tableValue => {
                if (element[DATA].userSettings.object === tableValue) {
                  throw { "type": 'OVERLOADED_SAME_SOURCE', "element": element[DATA][LABEL] };
                }
              })
            }

            // SATS
            if ((element.type === 'satnode') || (element.type === 'xrefnode')) {
              switch (true) {
                case (element[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === undefined):
                case (element[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === ''):
                  throw { "type": 'TABLE_ABBREVIATION', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][BUSINESS_KEY].length < 1 && !(element[DATA][DRAGGED_CONNECTION][CONNECTION_TYPE] === "HUB||SAT||LINK||LSAT")):
                  throw { "type": 'BUSINESS_KEY', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === undefined):
                  throw { "type": 'SOURCE_DELETED_FIELD_INDICATOR', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR] === undefined):
                  throw { "type": 'TALEND_DELETED_FIELD_INDICATOR', "element": element[DATA][LABEL] };
                case (element[DATA][DRAGGED_CONNECTION][CONNECTION_TYPE] === 'HUB||SAT||LINK||LSAT' && element[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === false && element[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR] === false):
                  throw { "type": 'DELETED_FIELD_INDICATOR', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true && element[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME] === ''):
                  throw { "type": 'DELETED_FIELD_NAME', "element": element[DATA][LABEL] };
                default:
                  break;
              }
            }
            // LSATS
            if (element.type === 'lsatnode') {

              switch (true) {
                case (element[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === undefined):
                case (element[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === ''):
                  throw { "type": 'TABLE_ABBREVIATION', "element": element[DATA][LABEL] };
                case isNotValidBusinessKey(element[DATA][USER_SETTINGS][BUSINESS_KEY]):
                  throw { "type": 'BUSINESS_KEY', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][DRIVING_KEY].length < 1):
                  throw { "type": 'DRIVING_KEY', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === undefined):
                  throw { "type": 'SOURCE_DELETED_FIELD_INDICATOR', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR] === undefined):
                  throw { "type": 'TALEND_DELETED_FIELD_INDICATOR', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][DRIVING_KEY_LABEL] === undefined):
                case (element[DATA][USER_SETTINGS][DRIVING_KEY_LABEL] === undefined || ''):
                  throw { "type": 'DRIVING_KEY_LABEL', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true && element[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME] === ''):
                  throw { "type": 'DELETED_FIELD_NAME', "element": element[DATA][LABEL] };
                default:
                  break;
              }

            }
            // XLINKS
            if ((element.type === 'xlinknodedelta') || (element.type === 'xlinknodefull')) {

              switch (true) {
                case (element[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === undefined):
                case (element[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === ''):
                  throw { "type": 'TABLE_ABBREVIATION', "element": element[DATA][LABEL] };
                case isNotValidBusinessKey(element[DATA][USER_SETTINGS][BUSINESS_KEY]):
                  throw { "type": 'BUSINESS_KEY', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][XLINK_NAME_UPDATED_LABEL] === undefined):
                case (element[DATA][USER_SETTINGS][XLINK_NAME_UPDATED_LABEL] === ''):
                  throw { "type": 'XLINK_NAME_UPDATED_LABEL', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === undefined):
                  throw { "type": 'SOURCE_DELETED_FIELD_INDICATOR', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR] === undefined):
                  throw { "type": 'TALEND_DELETED_FIELD_INDICATOR', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][XLINK_NAME_UPDATED_LABEL] === undefined):
                case (element[DATA][USER_SETTINGS][XLINK_NAME_UPDATED_LABEL] === ''):
                  throw { "type": 'XLINK_RENAME', "element": element[DATA][LABEL] };
                case (element[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true && element[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME] === ''):
                  throw { "type": 'DELETED_FIELD_NAME', "element": element[DATA][LABEL] };
                default:
                  break;
              }

            }
          }

        })
      } catch (e) {
        // Error types and responses to user:
        switch (true) {
          case e === 'Invalid link connections':
            addAlert({ msg: t('CLIENT_ERRORS.LINK_NODE_PENDING', { element: e.element }), isDismissible: false })
            validNodes = false;
            break;
          case e === 'Invalid xlink connections':
            addAlert({ msg: t('CLIENT_ERRORS.XLINK_NODE_PENDING', { element: e.element }), isDismissible: false })
            validNodes = false;
            break;
          case (e["type"] === 'multiple names'):
            addAlert({ msg: t('CLIENT_ERRORS.MULTIPLE_TABLE_NAME_EXISTS', { element: e["element"] }), isDismissible: false })
            validNodes = false;
            break;
          case (e["type"] === 'Table settings not set'):
            addAlert({ msg: t('CLIENT_ERRORS.SETTINGS_PEDDING', { element: e["element"] }), isDismissible: false })
            validNodes = false;
            break;
          case (e["type"] === 'Overloaded satellite rename'):
            addAlert({ msg: t('CLIENT_ERRORS.SETTINGS_PEDDING', { element: e["element"] }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'Overloaded satellite source table object'):
            addAlert({ msg: t('CLIENT_ERRORS.OVERLOAD_PENDING', { element: e["element"] }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'OVERLOADED_SAME_SOURCE'):
            addAlert({ msg: t('CLIENT_ERRORS.CANT_OVERLOAD', { element: e["element"] }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'TABLE_ABBREVIATION'):
            addAlert({ msg: t('CLIENT_ERRORS.ABBREVATION_NOT_SET', { element: e["element"] }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'XLINK_NAME_UPDATED_LABEL'):
            addAlert({ msg: t('CLIENT_ERRORS.NOT_SET', { element: e["element"], type: "Xlink" }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'BUSINESS_KEY'):
            addAlert({ msg: t('CLIENT_ERRORS.NOT_SET', { element: e["element"], type: "Business keys" }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'SOURCE_DELETED_FIELD_INDICATOR'):
            addAlert({ msg: t('CLIENT_ERRORS.NOT_SET', { element: e["element"], type: `"The source system has deleted record" option` }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'TALEND_DELETED_FIELD_INDICATOR'):
            addAlert({ msg: t('CLIENT_ERRORS.NOT_SET', { element: e["element"], type: `"Deleted field indicator inserted during a talend job and is NOT in the schema in the source system (does not land in the PSA schema table)"` }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'DRIVING_KEY'):
            addAlert({ msg: t('CLIENT_ERRORS.NOT_SET', { element: e["element"], type: "Driving keys" }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'DRIVING_KEY_LABEL'):
            addAlert({ msg: t('CLIENT_ERRORS.NOT_SET', { element: e["element"], type: "The Driving key name" }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'XLINK_RENAME'):
            addAlert({ msg: t('CLIENT_ERRORS.NOT_SET', { element: e["element"], type: "Table name" }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'DELETED_FIELD_INDICATOR'):
            addAlert({ msg: t('CLIENT_ERRORS.SOURCE_SYSTEM_CANNOT_BE_FALSE', { element: e["element"] }), isDismissible: false })
            validNodes = false;
            break
          case (e["type"] === 'DELETED_FIELD_NAME'):
            addAlert({ msg: t('CLIENT_ERRORS.NOT_SET', { element: e["element"], type: "Source deleted field name" }), isDismissible: false })
            validNodes = false;
            break
          default:
            addAlert({ msg: t('CLIENT_ERRORS.UNEXPECTED_ERROR', { e }), isDismissible: false, isIncident: true })
            validNodes = false;
            break;
        }
      }

    } else {
      addAlert({ msg: t('CLIENT_ERRORS.CREATE_NODE_AND_CONNECTIONS'), isDismissible: false })
      validNodes = false;
    }
    return validNodes;
  }

  const isNotValidBusinessKey = (businessKeyDict) => {
    const businessKeyData = Object.values(businessKeyDict)
    if (businessKeyData.length < 2) return true
    for (const value of businessKeyData) {
      if (value.length < 1) return true
    }
    return false
  }

  const verificationNext = () => {
    if (destinationSchema !== '' && destinationDataBase !== '') {
      if (Object.keys(selectedElements).length) {
        return true
      } else {
        addAlert({ msg: t('CLIENT_ERRORS.SELECT_CHECKBOX'), isDismissible: false })
        return false
      }
    } else {
      if (destinationDataBase === "") {
        addAlert({ msg: t('CLIENT_ERRORS.SELECT_DESTINATION_DATABASE'), isDismissible: false })
      } else {
        addAlert({ msg: t('CLIENT_ERRORS.SELECT_DESTINATION_SCHEMA'), isDismissible: false })
      }
      return false
    }
  }

  const handleNext = () => {
    if(activeStep === steps.length - 1) return
    switch (activeStep) {
      case 0:
        if (playgroundNext()) setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 1:
        if (verificationNext()) setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 2:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    if(activeStep === 0) return
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="design-page-container">
      <div className="desin-page-action-bar">
        <ActionBar activeStep={activeStep} />
      </div>
      <div className="design-page-navigation-bar">
        <Stack direction="row" alignItems="center" className="h-100">
          <Tooltip 
            title="Back" 
            placement="bottom" 
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -22],
                    },
                  },
                ],
              },
            }}
            arrow
          >
            <ArrowLeftRoundedIcon
              onClick={handleBack}
              className={classNames("navigation-back-button", {"back-button-disabled": activeStep === 0})}
            />
          </Tooltip>
          <Stepper activeStep={activeStep} className="w-100">
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps} sx={{ my: 1, mx: 4 }}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Tooltip 
            title="Next" 
            placement="bottom" 
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -22],
                    },
                  },
                ],
              },
            }}
            arrow
          >
            <ArrowRightRoundedIcon 
              onClick={handleNext} 
              className={classNames("navigation-next-button", {"next-button-disabled": activeStep === steps.length - 1})}
              // className="navigation-next-button"
            />
          </Tooltip>
        </Stack>
      </div>
      <div className="design-page-alert">
        <CustomAlert />
      </div>
      <div className="design-page-content">      
        {activeStep === 0 &&
          <PlayGround />
        }
        {activeStep === 1 &&
          <VerificationPage
            DDLPrefix={DDLPrefix}
            setDDLPrefix={setDDLPrefix}
            procedurePrefix={procedurePrefix}
            setProcedurePrefix={setProcedurePrefix}
            origFieldInclude={origFieldInclude}
            setOrigFieldInclude={setOrigFieldInclude}
            setIsLoading={setIsLoading}
          />
        }
        {activeStep === 2 &&
          <ProceduresPage
            DDLPrefix={DDLPrefix}
            procedurePrefix={procedurePrefix}
            origFieldInclude={origFieldInclude}
          />
        }
      </div>
    </div>
  )
}

export default DesignPage;
