export const HUB_SATELLITE = 'HUB & SAT - Delta'
export const LINK_SATELLITE = 'LINK & LSAT - Delta'
export const REF_SATELLITE = 'REF & SAT - Delta'
export const XREF = 'XREF - Full'
export const XLINK_DELTA = 'XLINK - Delta'
export const XLINK_FULL = 'XLINK - Full'
export const HUB_SAT_LINK_LSAT = 'HUB, SAT, LINK & LSAT - Delta'
export const DATA = 'data'
export const DATABASE = 'database'
export const SCHEMA = 'schema'
export const SOURCE_TABLE = 'table'
export const NODE_TYPE = 'nodeLink'
export const DESTINATION_SCHEMA = 'destinationSchema'
export const DATABASE_LIST = 'databaseList'
export const SCHEMA_LIST = 'schemaList'
export const TABLE_LIST = 'tableList'
export const NODELINK_LIST = 'nodeLinkList'
export const LABEL = 'label'
export const PROCEDURE_NAME = 'procedureName'
export const DRAGGED_CONNECTION = 'draggedConnection'
export const CONNECTED_ENTITIES = 'connectedEntities'
export const CONNECTION_TYPE = 'connectionType'
export const TABLE_ABBREVIATION = 'tableAbbreviation'
export const TABLE_REFERENCE = 'tableReference'
export const OBJECT_SCHEMA = 'objectSchema'
export const OBJECT = 'object'
export const SELECTED_COLUMNS = 'selectedColumns'
export const BUSINESS_KEY = 'businessKey'
export const SKIP_IN_SYSHASH = 'skipSyshash'
export const SYS_SEQUENCER = 'sysSequencer'
export const SOURCE_DELETED_FIELD_INDICATOR = 'deletedFieldIndicator'
export const TALEND_DELETED_FIELD_INDICATOR = 'deletedFieldNameIndicator'
export const SOURCE_DELETED_FIELD_NAME = 'sourceDeletedFieldName'
export const SATELLITE_UPDATED_LABEL = 'satelliteUpdatedLabel'
export const HUB_NAME_UPDATED_LABEL = 'hubNameUpdatedLabel'
export const XLINK_NAME_UPDATED_LABEL = 'xlinkNameUpdatedLabel'
export const USER_SETTINGS = 'userSettings'
export const HUB_CONNECTIONS = 'hubConnections'
export const HUB_ORDER = 'hubOrder'
export const LINK_STATUS = 'linkStatus'
export const DRIVING_KEY_LABEL = 'drivingKeyLabel'
export const DRIVING_KEY = 'drivingKey'
export const ENABLE_NODE_SETTINGS = 'enableNodeSettings'
export const OVERLOAD_SATELLITE_TABLES_LIST = 'overloadSatelliteTablesList'
export const OVERLOAD_SOURCE_TABLES_OBJECT = 'overloadSourceTablesObject'
export const LOADED_TABLES = 'loadedTables'
export const LOADED_TABLE = 'loadedTable'
