import Accordion from 'react-bootstrap/Accordion'
import Container from 'react-bootstrap/Container'

const CollapsibleBar = ({ title, children }) => {
  return (
    // <Container className="my-2">
    <Accordion >
      <Accordion.Item eventKey={title}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    // </Container>
  )
}

export default CollapsibleBar