export const getLocalStorageData = (key) => {
  const data = localStorage.getItem(key)
  if(data != null) {
    return JSON.parse(data)
  }
  return null
}

export const setLocalStorageData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data))
}

// import { useState, useEffect } from 'react';

// // const useLocalStorage = ( key, initialValue ) => {
// //   const [ value, setValue ] = useState(() => {
// //     const jsonValue = localStorage.getItem(key)
// //     console.log(key)
// //     if(jsonValue != null) {
// //       return JSON.parse(jsonValue)
// //     }
// //     return initialValue
// //   })

// //   useEffect(() => {
// //     if(value !== null)  localStorage.setItem(key, JSON.stringify(value))
// //   }, [ key, value ])

// //   return [ value, setValue ]
// // }

// export default useLocalStorage;