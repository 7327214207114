import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import { useDesignsContext } from '../Context/DesignsContext';

const DesignsContent = ({ data, recentDesign, toggleData }) => {
  const { openDesign } = useDesignsContext()
  const dateTimeFormatter = (dateTime) => {
    const date = new Date(dateTime)
    const formattedDate = date.toLocaleDateString("en-GB")
    const formattedTime = date.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })
    return `${formattedDate} ${formattedTime}`
  }
  return (
    <>
      <div className="card_design_wrapper">   
        {(data?.length === 0) ? 
          <div className="main-content-top-no-design-text">There are no matches for your search</div>
        :
          <div className="card_design">
            {data?.map(design => {
              return (
                <>
                  <Card variant="outlined" className="card_display"
                    key={design?.CHART_ID}
                    onClick={() => openDesign(design?.CHART_ID, design)}
                    role='button'>
                    <div className="card_display_table_name">
                      {design?.TABLE_NAME}
                    </div>
                    <div className="card_display_business_name">
                      {design?.BUSINESS_NAME}
                    </div>
                    <div className="card_display_updatedby">
                      <span className="card_label">Last updated by: </span>
                      <span className="card_text">{design?.UPDATED_BY}</span>
                    </div>
                    <div className='card_display_updated_timestamp'>
                      <span className="card_label">Last update timestamp: </span>
                      <span className="card_text">{dateTimeFormatter(design?.LAST_UPDATED)}</span>

                    </div>

                  </Card>
                </>
              )
            })}
          </div>
        }   
      </div>

    </>
  );
};

export default DesignsContent;

