import { useState, createContext, useContext } from "react";
import { getIncomers, getOutgoers } from "react-flow-renderer";
import { DATA, DRAGGED_CONNECTION, CONNECTED_ENTITIES, HUB_CONNECTIONS, HUB_ORDER } from "../Components/ReactFlowRenderer/CustomNodes/NodeLinkTypes";

export const ReactFlowContext = createContext(null)

export const ReactFlowContextProvider = ({ children }) => {
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  
  const getElements = () => {
    let elements = []
    setReactFlowInstance((instance) => {
      if (instance !== null) {
        elements = instance.getElements()
      }
      return instance
    })
    return elements
  }

  const getSatConnections = (element) => {
    const satList = [];
    getIncomers(element, getElements()).forEach(nodeItem => {
      if (nodeItem.type === 'satnode') satList.push(nodeItem)
    })
    return satList
  }

  const getLsatConnections = (element) => {
    const lsatList = []
    getOutgoers(element, getElements()).forEach(nodeItem => {
      if (nodeItem.type === 'lsatnode') lsatList.push(nodeItem)
    })
    return lsatList
  }

  const getLinkConnections = (element) => {
    const linkList = [];
    getIncomers(element, getElements()).forEach(nodeItem => {
      if (nodeItem.type === 'linknode') linkList.push(nodeItem.data.label)
    })
    return linkList
  }

  const getLinkXlinkConnections = (element) => {
    const linkXlinkList = [];
    getIncomers(element, getElements()).forEach(nodeItem => {
      if (nodeItem.type === 'linknode' || nodeItem.type === 'xlinknodedelta' || nodeItem.type === 'xlinknodefull') linkXlinkList.push(nodeItem)
    })
    return linkXlinkList
  }

  const getConnectedHubSatPairIdsToLinkXlink = (linkXlinkId) => {
    let hubSatIdsList = []
    const linkXlinkNode = getNodeById(linkXlinkId)
    const connectedHubIds = linkXlinkNode[DATA][HUB_CONNECTIONS][HUB_ORDER]
    const connectedHubs = getNodesByNodeIdList(connectedHubIds)
    connectedHubs.forEach((node) => {
      const connectedEntities = node[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES]
      if(connectedEntities.length > 2) {
        const connectedLinkNodeId = node[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][2]
        if(connectedLinkNodeId !== linkXlinkId) {
          hubSatIdsList = hubSatIdsList.concat(getConnectedHubSatPairIdsToLinkXlink(connectedLinkNodeId))
        }
      }
      hubSatIdsList = hubSatIdsList.concat(connectedEntities)
    })
    return hubSatIdsList
  }

  const getHubConnections = (element) => {
    const hubList = [];
    getOutgoers(element, getElements()).forEach(nodeItem => {
      if (nodeItem.type === 'hubnode') hubList.push(nodeItem)
    })
    return hubList
  }

  const getRefConnections = (element) => {
    const refList = [];
    getOutgoers(element, getElements()).forEach(nodeItem => {
      if (nodeItem.type === 'refnode') refList.push(nodeItem.data.label)
    })
    return refList
  }

  const getNodeById = (id) => {
    const result = getElements().find(nodeItem => nodeItem.id === id)
    return result
  }

  const getNodesByNodeIdList = (nodeIdList) => {
    let foundNode
    const result = getElements().filter(element => {
      foundNode = false
      for(let nodeId of nodeIdList) {
        if(nodeId === element.id) {
          foundNode = true
          break
        }
      }
      return foundNode
    })
    return result
  }

  const getNodeLabels = (nodeIdList) => {
    let nodeLabels = []
    getElements().forEach(element => {
      for(let nodeId of nodeIdList) {
        if(nodeId === element.id) {
          nodeLabels.push(element.data.label)
          break
        }
      }
    })
    return nodeLabels
  }

  const getDesignObject = () => {
    let designObject = []
    setReactFlowInstance((instance) => {
      if (instance) {
        designObject = instance.toObject();
      }
      return instance
    })
    return designObject
  } 

  return (
    <ReactFlowContext.Provider value={{ reactFlowInstance, setReactFlowInstance, getElements, getSatConnections, getLsatConnections, getHubConnections, getRefConnections, getLinkConnections, getLinkXlinkConnections, getNodeById, getDesignObject, getNodesByNodeIdList, getNodeLabels, getConnectedHubSatPairIdsToLinkXlink }}>
      {children}
    </ReactFlowContext.Provider>
  )
}

export const useReactFlowContext = () => {
  return useContext(ReactFlowContext)
}