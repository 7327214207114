import { useModalContext } from '../../../Context/ModalContext';
import React, { useState, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import './CustomNodesStyle.css';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  DATA,
  USER_SETTINGS,
  TABLE_ABBREVIATION,
  BUSINESS_KEY,
  SOURCE_DELETED_FIELD_INDICATOR,
  SOURCE_DELETED_FIELD_NAME,
  TALEND_DELETED_FIELD_INDICATOR,
  XLINK_NAME_UPDATED_LABEL,
  SELECTED_COLUMNS,
} from './NodeLinkTypes';
import { usePlaygroundContext } from '../../../Context/PlaygroundContext';

const LinkNode = (node) => {
  const { enableNodeSettingsModalShow, setNodeSettingsModalCurrentNode } = useModalContext()
  const { onElementsRemove } = usePlaygroundContext()
  const { linkStatus, label, enableNodeSettings } = node[DATA];
  const [warning, setWarning] = useState(true)
  const [enableSettings, setEnableSettings] = useState(enableNodeSettings)

  const onDelete = () => {
    onElementsRemove([node])
  }

  useEffect(() => {
    if(node[DATA][USER_SETTINGS] !== undefined && !linkStatus) {
      switch (true) {
        case (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === undefined):
        case (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === ''):
        case isNotValidBusinessKey(node[DATA][USER_SETTINGS][BUSINESS_KEY]):
        case (node[DATA][USER_SETTINGS][SELECTED_COLUMNS] === undefined || node[DATA][USER_SETTINGS][SELECTED_COLUMNS].length < 1):
        case (node[DATA][USER_SETTINGS][XLINK_NAME_UPDATED_LABEL] === undefined):
        case (node[DATA][USER_SETTINGS][XLINK_NAME_UPDATED_LABEL] === ''):
        case (node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === undefined):
        case (node[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR] === undefined):
        case (node[DATA][USER_SETTINGS][XLINK_NAME_UPDATED_LABEL] === undefined):
        case (node[DATA][USER_SETTINGS][XLINK_NAME_UPDATED_LABEL] === ''):
        case (node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true && node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME] === ''):
          setWarning(true)
          break
        default:
          setWarning(false)
          break;
      }
      if(!(node[DATA][USER_SETTINGS].hasOwnProperty(SELECTED_COLUMNS))){
        if((node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] !== undefined) && (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] !== '')){
          setWarning(false);
        }
      }
    } else {
      setWarning(true)
    }
    setEnableSettings(!linkStatus)
  }, [ node[DATA][USER_SETTINGS], linkStatus ])

  const isNotValidBusinessKey = (businessKeyDict) => {
    const businessKeyData = Object.values(businessKeyDict)
    if(businessKeyData.length < 2) return true
    for (const value of businessKeyData) {
      if (value.length < 1) return true
    }
    return false
  }

  const onClick = () => {
    setNodeSettingsModalCurrentNode(node)
    enableNodeSettingsModalShow(node.id)
  }

  return (
    <div className="xlinkfull">
      <Handle
        type="source"
        position="bottom"
        style={{ background: '#555' }}
      />
      <div className="py-1 px-2 position-relative">
        <Tooltip title="XLink Full" placement="top-start" arrow>
          <span>{label}</span>
        </Tooltip>
        <span className="position-absolute top-0 start-100 translate-middle badge fs-6" hidden={!warning}>
          <Tooltip 
            title={linkStatus ? "XLINK Full node needs to be connected with minimum 2 HUB nodes" : "Node settings incomplete."} 
            placement="top-start" 
            arrow
          >
            <ErrorTwoToneIcon sx={{ fontSize: 25, color: 'red' }} />
          </Tooltip>
        </span>
        <span className="ms-2" onClick={onClick} style={{ cursor: 'pointer' }} hidden={!enableSettings}>
          <Tooltip 
            title="Node settings" 
            placement="bottom-end" 
            arrow 
          >
            <SettingsIcon style={{ fontSize: 'large', marginBottom: "0.175rem" }} />
          </Tooltip>
        </span>
        {node.selected && (
          <span className="ms-2" onClick={onDelete} style={{ cursor: 'pointer' }}>
            <Tooltip 
              title="Delete node along with its dependents" 
              placement="bottom-start" 
              arrow 
            >
              <DeleteForeverIcon style={{ fontSize: 'large', marginBottom: "0.175rem" }} />
            </Tooltip>
          </span>
          )
        }
      </div>
    </div>
  );
}

export default LinkNode;
