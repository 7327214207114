import { useState, createContext, useContext } from "react";
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsconfig);

export const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [snowflakeAccount, setSnowflakeAccount] = useState('');
  const [userRegistered, setUserRegistered] = useState(false)
  const accountsList = {
    ROCHE_DIA: { 
      accountURL: "roche_dia.eu-central-1",
      sourceSchemaFilter: '^PSA',
      destinationSchemaFilter: '^RAW_DV'
    },
    CHLOCAL: { 
      accountURL: "roche-dia-chlocal.switzerland-north.azure",
      sourceSchemaFilter: '^PSA',
      destinationSchemaFilter: '^RAW_DV'
    },
    PDIL: { 
      accountURL : "roche_pd.eu-central-1",
      sourceSchemaFilter: '_RDS_',
      destinationSchemaFilter: '_RDV_',
    },
    NAM: { 
      accountURL : "roche_dia_namlocal.us-east-2.aws",
      sourceSchemaFilter: '^PSA',
      destinationSchemaFilter: '^RAW_DV',
    },
  }

  const getAccountNameByURL = (URL) => {
    let accountName = ""
    if(URL !== "") {
      Object.entries(accountsList).forEach(([key, value]) => {
        if(value.accountURL === URL) accountName = key
      })
    }
    return accountName
  }

  async function getUser() {
    const userDetails = await Auth.currentUserInfo()
    setUser(userDetails)
  }

  const login = async () => {
    await Auth.federatedSignIn()
    await getUser()
  }

  const logout = async () => {
    await Auth.signOut()
    setUser(null)
  }

  const jwtToken = async () => {
    let session = await Auth.currentSession()
    //console.log('Session refreshed')
    let token = session.getIdToken().getJwtToken()
    return token
  }


  return (
    <AuthContext.Provider value={{ user, snowflakeAccount, setSnowflakeAccount, accountsList, getAccountNameByURL, login, logout, getUser, jwtToken, userRegistered, setUserRegistered }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}