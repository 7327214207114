class IdleTimer {
  constructor({ userSignedIn, disableForLocalEnv, timeout, onTimeout, onExpired }) {
    this.userSignedIn = userSignedIn
    this.disableForLocalEnv = disableForLocalEnv
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.immediateUpdate = 'IMMEDIATE_UPDATE';
    this.delayedUpdate = 'DELAYED_UPDATE';

    if (!this.userSignedIn || this.disableForLocalEnv) {
      localStorage.removeItem("_expiredTime");
    }

    const expiredTime = parseInt(localStorage.getItem("_expiredTime") || 0, 10)
    if(expiredTime > 0 && expiredTime < Date.now() && this.userSignedIn) {
      onExpired();
      return
    }

    this.eventHandler = this.updateExpiredTime.bind(this);
    if(this.userSignedIn && !this.disableForLocalEnv) {
      this.tracker();
      this.startInterval();
    }
  }

  startInterval() {
    this.updateExpiredTime(this.immediateUpdate)
    this.interval = setInterval(() => {
      if(this.userSignedIn) {
        const expiredTime = parseInt(localStorage.getItem("_expiredTime") || 0, 10)
        if((expiredTime < Date.now())) {
          if(this.onTimeout) {
            this.cleanUp();
            this.onTimeout();
          }
        }
      } else {
        if(this.interval)  {
          clearInterval(this.interval);
        }
      }
    }, 1500)
  }

  updateExpiredTime(updateMode = this.delayedUpdate) {
    if(this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    if(this.userSignedIn) {
      if(updateMode === this.immediateUpdate) {
        localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
      } else {
        this.timeoutTracker = setTimeout(() => {
          localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
        }, 750)
      }
    }
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    clearInterval(this.interval);
    if(this.userSignedIn) {
      clearTimeout(this.timeoutTracker);
      localStorage.removeItem("_expiredTime");
      window.removeEventListener("mousemove", this.eventHandler);
      window.removeEventListener("scroll", this.eventHandler);
      window.removeEventListener("keydown", this.eventHandler);
    }
  }
}

export default IdleTimer;