import Tooltip from '@mui/material/Tooltip'

export const removeSpecialSymbols = (str) => {
  return str.replace(/\>|\<|\:|\"|\?|\*|\\|\||\//g, '')
}

export const formatName = (str) => {
  const str1 = str.replace(/\/BIC|>|<|:|"|\?|\*|\\|\|/gi, '').toUpperCase()
  const str2 = str1.replace(/\//, '')
  return str2.replace(/\//g, '_')
}

export const formatFilename = (str) => {
  const str1 = str.replace(/^[\s.\-_]/, '')
  return str1.replace(/[#<>$+%!`&*'|{}?"=/:\\\s@]/g, '')
}

export const stringClipper = (str, clipLength) => {
  return (
    str.length > clipLength ? (
      <Tooltip title={str} placement="bottom-start" arrow>
        <span>{`${str.substring(0, clipLength)}...`}</span>
      </Tooltip>
      // <abbr title={str}>{`${str.substring(0, clipLength)}...`}</abbr>
    ) : str
  )
}