import { useCallback, useEffect } from "react";
import { isNode } from "react-flow-renderer";
import {
  DATA,
  DRAGGED_CONNECTION,
  CONNECTED_ENTITIES,
} from '../Components/ReactFlowRenderer/CustomNodes/NodeLinkTypes';
import { useReactFlowContext } from "../Context/reactFlowContext";

const useElementCopy = (
  elements,
  setElements,
  selectedElements,
  setSelectedElements,
  updateDesign,
  updateDelay,
) => {
  const { getLsatConnections, getNodesByNodeIdList, getSatConnections, getConnectedHubSatPairIdsToLinkXlink } = useReactFlowContext()

  const handleCopy = useCallback(
    (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "c" || event.key === "C") &&
        (selectedElements.length > 0)
      ) {
        let finalNodesId = [], finalEdges = [];
        selectedElements.forEach((el) => {
          if(isNode(el)) {
            if(!finalNodesId.includes(el.id)) {
              switch(el.type) {
                case "hubnode":
                  const satConnections = getSatConnections(el)
                  const satConnectionsId = satConnections.map((node) => node.id)
                  finalNodesId = finalNodesId.concat(satConnectionsId)
                  if(el[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].length > 2) {
                    finalNodesId = finalNodesId.concat(el[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES])
                    const linkNodeId = el[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][2]
                    finalNodesId = finalNodesId.concat(getConnectedHubSatPairIdsToLinkXlink(linkNodeId))
                  } else {
                    finalNodesId.push(el.id)
                  }
                  break;
                case "satnode":
                  finalNodesId = finalNodesId.concat(el[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES])
                  if(el[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].length > 2) {
                    const linkNodeId = el[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][2]
                    finalNodesId = finalNodesId.concat(getConnectedHubSatPairIdsToLinkXlink(linkNodeId))
                  }
                  break;
                case "linknode":
                  const lSatConnections = getLsatConnections(el)
                  const lSatConnectionsId = lSatConnections.map((node) => node.id)
                  finalNodesId = finalNodesId.concat(lSatConnectionsId)
                  finalNodesId = finalNodesId.concat(getConnectedHubSatPairIdsToLinkXlink(el.id))
                  finalNodesId.push(el.id)
                  break;
                case "lsatnode":
                  finalNodesId = finalNodesId.concat(el[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES])
                  if(el[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].length > 2) {
                    const linkNodeId = el[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][2]
                    finalNodesId = finalNodesId.concat(getConnectedHubSatPairIdsToLinkXlink(linkNodeId))
                  } else {
                    const linkNodeId = el[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][0]
                    finalNodesId = finalNodesId.concat(getConnectedHubSatPairIdsToLinkXlink(linkNodeId))
                  }
                  break;
                case "refnode":
                  const refSatConnections = getSatConnections(el)
                  const refSatConnectionsId = refSatConnections.map((node) => node.id)
                  finalNodesId = finalNodesId.concat(refSatConnectionsId)
                  finalNodesId.push(el.id)
                  break;
                case "xrefnode":
                  finalNodesId.push(el.id)
                  break;
                case "xlinknodefull":
                case "xlinknodedelta":
                  finalNodesId.push(el.id)
                  finalNodesId = finalNodesId.concat(getConnectedHubSatPairIdsToLinkXlink(el.id))
                  break;
                default:
                  break;
              }
            }
          }
        })
        const uniqueFinalNodesId = Array.from(new Set(finalNodesId))
        elements.forEach((element) => {
          if(!isNode(element)) {
            if(uniqueFinalNodesId.includes(element.source) && uniqueFinalNodesId.includes(element.target)) {
              finalEdges.push(element)
            }
          }
        })
        const finalNodes = getNodesByNodeIdList(uniqueFinalNodesId)
        localStorage.setItem(
          "copiedElements",
          JSON.stringify([...finalNodes, ...finalEdges] || [])
        );
      }

      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "v" || event.key === "V")
      ) {
        const copiedElements = JSON.parse(localStorage.getItem("copiedElements")) || [];

        if (copiedElements.length > 0) {
          setElements((els) => {
            const combinedElements = [ ...els, ...copiedElements]
            const finalElements = combinedElements.reduce((acc, current) => {
              const duplicateElement = acc.find((el) => el.id === current.id)
              if(!duplicateElement) {
                acc.push(current)
              }
              return acc
            }, [])
            return finalElements
          });
          setTimeout(() => {
            updateDesign()
          }, updateDelay)
          localStorage.removeItem("copiedElements")
          setSelectedElements([])
        }
      }
    },
    [elements, setElements, selectedElements, setSelectedElements, getConnectedHubSatPairIdsToLinkXlink, getLsatConnections, getNodesByNodeIdList, getSatConnections, updateDelay, updateDesign]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleCopy);

    return () => {
      document.removeEventListener("keydown", handleCopy);
    };
  }, [handleCopy]);
};

export default useElementCopy;