import React, { useLayoutEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

const ExpandableText = ({ item, deleteOption }) => {
  const textRef = useRef();
  const [showIcon, setShowIcon] = useState(false);
  const [expandedOption, setExpandedOption] = useState(null);

  useLayoutEffect(() => {
    if (textRef.current.clientWidth < textRef.current.scrollWidth) {
      setShowIcon(true);
    }
  }, [textRef]);

  const toggleItem = (item) =>
    setExpandedOption((prev) => (prev === item ? null : item));

  return (
    <>
      <span
        ref={textRef}
        className={`column-settings-option-data-text ${
          expandedOption === item ? "open" : ""
        }`}
        onClick={() => toggleItem(item)}
      >
        {item.name}
      </span>
      {item.deletable ? (
        <DeleteIcon onClick={() => deleteOption(item.id)} />
      ) : null}
      {showIcon ? (
        <ExpandMoreIcon
          onClick={() => toggleItem(item)}
          className={`column-settings-option-data-icon ${
            expandedOption === item ? "open" : ""
          }`}
        />
      ) : null}
    </>
  );
};

export default ExpandableText;
