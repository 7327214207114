import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

import { useReactFlowContext } from "../Context/reactFlowContext";

const Search = ({ reactFlowWrapper }) => {
  const containerRef = useRef();
  const [filteredList, setFilteredList] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const { getElements, reactFlowInstance, getNodeById } = useReactFlowContext();
  const elementsList = getElements();
  const elementStyle = {
    hubnode: "hubNode",
    refnode: "refNode",
    xrefnode: "xrefNode",
    linknode: "linkNode",
    xlinknodedelta: "xlinkdelta",
    xlinknodefull: "xlinkfull",
    satnode: "satNode",
    lsatnode: "lsatNode",
  };

  const handleSearch = (e) => {
    const newValue = e.target.value.toUpperCase();
    setSearchWord(newValue);

    if (newValue.length < 1) {
      setFilteredList([]);
      return;
    }

    setFilteredList(
      elementsList.filter((element) => element.data?.label.includes(newValue))
    );
  };

  const handleElementClick = (id) => {
    const node = getNodeById(id);
    const centerX =
      (reactFlowWrapper?.offsetWidth ? reactFlowWrapper.offsetWidth / 2 : 100) -
      node.position.x -
      120;
    const centerY =
      (reactFlowWrapper?.offsetHeight
        ? reactFlowWrapper.offsetHeight / 2
        : 40) - node.position.y;
    reactFlowInstance.setTransform({
      x: centerX,
      y: centerY,
      zoom: 1,
    });
  };

  return (
    <div ref={containerRef} style={{ flex: 1 }}>
      <Form.Control
        className="mb-2"
        value={searchWord}
        onChange={handleSearch}
        type="text"
        placeholder="Search Node Name"
      />
      <div
        style={{
          maxHeight: containerRef.current?.offsetHeight - 50,
          overflowY: "auto",
        }}
      >
        {filteredList.map((elem, key) => (
          <Card
            style={{ cursor: "pointer" }}
            className={`mb-2 ${elementStyle[elem.type]}`}
            onClick={() => handleElementClick(elem.id)}
            key={key}
          >
            <Card.Title className="mt-2 h6 text-center">
              {elem.data?.label}
            </Card.Title>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Search;
