import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

useDebounce.propTypes = {
  value: PropTypes.any,
  delay: PropTypes.number,
};

export default useDebounce;
