import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { useAuth } from "../Context/AuthContext";
import CustomDropdown from "../Components/CustomDropdown";
import SearchTable from "../Components/SearchTable";
import { useSnowFlakeContext } from "../Context/SnowFlakeContext";
import useDebounce from "../helpers/useDebounce";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { usePlaygroundContext } from "../Context/PlaygroundContext";
import { formatFilename } from "../Logic/cleanNames";
import { useSocketioContext } from "../Context/SocketioContext";
import { useAlertContext } from "../Context/AlertContext";
import { useTranslation } from 'react-i18next'
import { useDesignsContext } from "../Context/DesignsContext";
import { dva_version_with_mandate_origfields } from '../Constant/FileNameList';


const VerificationPage = ({
  DDLPrefix,
  setDDLPrefix,
  procedurePrefix,
  setProcedurePrefix,
  origFieldInclude,
  setOrigFieldInclude,
  setIsLoading,
}) => {
  const { socket } = useSocketioContext();
  const {currentDesign} = useDesignsContext();
  const [filteredSchemaList, setFilteredeSchemaList] = useState([]);
  const [filePrefixIndicator, setFilePrefixIndicator] = useState(
    DDLPrefix !== "" || procedurePrefix !== "" ? true : false
  );
  const { selectedElements, setSelectedElements } = usePlaygroundContext();
  const {
    databaseList,
    destinationSchema,
    destinationDataBase,
    setDatabaseState,
    dvaVersion
  } = useSnowFlakeContext();
  const [ displayElements, setDisplayElements ] = useState([])
  const [checkAll, setCheckAll] = useState(false);
  const [origBtn, setOrigBtn] = useState(false);

  const { user, snowflakeAccount, accountsList } = useAuth();
  const { addAlert, alertTypes } = useAlertContext()
  const [searchWord, setSearchWord] = useState("")
  const debounceSearch = useDebounce(searchWord,1000);
  const [ data,setData] = useState([])
  const {t} = useTranslation()
  const userName = user?.attributes?.name
  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ]

  useEffect(() => {
    if (socket) {
      setIsLoading(true)
      socket.emit('getFilteredDesignElements', {
        account: accountsList[snowflakeAccount]?.accountURL,
        designId: currentDesign.designId,
        searchFilter: debounceSearch,
        userId: userName
      }, (responseData) => {
        setData(responseData);  
        setIsLoading(false)
      });
    }
  }, [socket, debounceSearch, accountsList, snowflakeAccount, currentDesign, userName]);

  useEffect(() => {
    //Set dest scheme if database is already selected
    if(destinationDataBase){
      socket?.emit("getSchemas", {
        database: destinationDataBase,
        account: accountsList[snowflakeAccount]?.accountURL,
      });
    }
    setIsLoading(true)
    if (Object.keys(selectedElements).length === 0) {
      setSelectedElements(() => {
        let elementsList = {};
        data?.forEach((element) => {
          const connectedEntitiesKey = element["key"]
          const updatedElementObject = { ...element, checked: false }
          elementsList[connectedEntitiesKey] = { ...updatedElementObject };
        });
        setDisplayElements(() => Object.values(elementsList))
        setIsLoading(false)
        return elementsList;
      });
    } else {
      setSelectedElements((prevList) => {
        let elementsList = {};
        let checkedElements = [], unCheckedElements = [];
        const prevSelectedElements = Object.keys(prevList);
        data?.forEach((element) => {
          const connectedEntitiesKey = element["key"]
          elementsList[connectedEntitiesKey] = {
            ...element,
            checked: prevSelectedElements.includes(connectedEntitiesKey)
              ? prevList[connectedEntitiesKey]["checked"]
              : false,
          };
          if(elementsList[connectedEntitiesKey]["checked"]) {
            const isElementPresent = checkedElements.find((el) => el["key"] === connectedEntitiesKey)
            if(isElementPresent === undefined) {
              checkedElements.push(elementsList[connectedEntitiesKey])
            }
          } else {
            const isElementPresent = unCheckedElements.find((el) => el["key"] === connectedEntitiesKey)
            if(isElementPresent === undefined) {
              unCheckedElements.push(elementsList[connectedEntitiesKey])
            }
          } 
        });
        setDisplayElements([ ...checkedElements, ...unCheckedElements ])
        setCheckAll(unCheckedElements.length > 0 ? false : true );
        setIsLoading(false)
        return prevList;
      });
    }
  }, [data]);

  useEffect(() => {
    if (socket !== null) {
      if (databaseList.length === 0) {
        //Socket event 'getDatabases' requested from client to get the databases list from server
        setIsLoading(true)
        socket?.emit("getDatabases", {
          account: accountsList[snowflakeAccount]?.accountURL,
        });
      }

      socket.on("receiveDatabases", async (responsePayload) => {
        setIsLoading(false)
        setDatabaseState((prevState) => {
          return { ...prevState, databaseList: responsePayload.databases };
        });
      });

      socket.on("receiveSchemas", async (responsePayload) => {
        const filterPattern =
          accountsList[snowflakeAccount]?.destinationSchemaFilter || "";
        const schemaFilter = new RegExp(`${filterPattern}`, "i");
        const newSchemaList = responsePayload.schemas.filter((schema) =>
          schemaFilter.test(schema)
        );
        setIsLoading(false)
        setFilteredeSchemaList(newSchemaList);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (!dva_version_with_mandate_origfields.includes(dvaVersion)) setOrigBtn(true)
  }, [dvaVersion])
  useEffect(() => {
    setIsLoading(true)
    let checkedElements = [], unCheckedElements = [];
   

    const selectedDbs = new Set()
    setDisplayElements((prevElements) => {
      const elementsArray = [ ...prevElements ]
      elementsArray.forEach((element) => {
        const key = element["key"]
        element["checked"] = selectedElements[key]['checked']
        if(element["checked"]) {
          selectedDbs.add(element["database"])
          checkedElements.push(element)
        } else {
          unCheckedElements.push(element)
        }
      })
      setCheckAll(unCheckedElements.length > 0 ? false : true );
      if(Array.from(selectedDbs.values()).length > 1){
        addAlert({ msg: t('CLIENT_WARNING.MULTIPLE_DATABASES'), isDismissible: true, variant: alertTypes["CLIENT_WARNING"] })
      }
      setIsLoading(false)
      return [ ...checkedElements, ...unCheckedElements ]
    })
  }, [selectedElements]);

  const handleAllCheckboxChange = () => {
    let elementsList = { ...selectedElements };
    setCheckAll((prevCheckedState) => {
      displayElements.forEach((element) => {
        const key = element["key"]
        elementsList[key]["checked"] = !prevCheckedState;
      })
      return !prevCheckedState;
    });
    setSelectedElements({ ...elementsList });
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedElements((prevList) => {
      let elementsList = { ...prevList };
      elementsList[id]["checked"] = checked
      return elementsList;
    });
  };

  const handleSchema = (value) => {
    setDatabaseState((prevState) => {
      return { ...prevState, destinationSchema: value };
    });
  };

  const handleDestinationDB = (value) => {
    setDatabaseState((prevState) => {
      return { ...prevState, destinationDataBase: value };
    });
    if (value) {
      socket?.emit("getSchemas", {
        database: value,
        account: accountsList[snowflakeAccount]?.accountURL,
      });
    }
  };

  const handleOrigRadioChange = (event) => {
    setOrigFieldInclude(event.target.value === 'true');
  };
  const handleRadioChange = (event) => {
    const [radioLabel, radioValue] = event.target.id.split("_");
    if (radioLabel === "file-prefix") {
      if (radioValue === "Yes") setFilePrefixIndicator(true);
      else {
        setFilePrefixIndicator(false);
        setDDLPrefix("");
        setProcedurePrefix("");
      }
    }
  };

  return (
    <div className="w-100 h-100">
      <Stack direction="horizontal" gap={2} className="my-2 mx-3">
        <Stack direction="horizontal" gap={2} className="ps-5 w-50">
          <div className="me-2">Select Destination Database :</div>
          <div>
            <CustomDropdown
              needToBeAuthorized={false}
              dropdownList={databaseList}
              dropdownName="Destination Database"
              handleSelection={handleDestinationDB}
              selectedValue={destinationDataBase}
              filterable={true}
              clipLength={30}
            />
          </div>
        </Stack>
        <Stack direction="horizontal" gap={2} className="ps-5 w-50">
          <div className="me-2">Select Destination Schema :</div>
          <div>
            <CustomDropdown
              needToBeAuthorized={false}
              dropdownList={filteredSchemaList}
              dropdownName="Destination Schema"
              handleSelection={handleSchema}
              selectedValue={destinationSchema}
              filterable={true}
              clipLength={30}
            />
          </div>
        </Stack>
      </Stack>
      <Stack direction="horizontal" className="my-2 mx-3">
        <Stack direction="horizontal" gap={2} className="ps-5 w-50">
          <div className="me-2">
            Do you want to add a file prefix to the generated files :
          </div>
          <div>
            <Form onSubmit={(event) => event.preventDefault()}>
              {["Yes", "No"].map((value) => {
                return (
                  <Form.Check
                    inline
                    type="radio"
                    id={`file-prefix_${value}`}
                    data-testid={`file-prefix_${value}`}
                    label={value}
                    onChange={handleRadioChange}
                    checked={
                      value === "Yes"
                        ? filePrefixIndicator
                        : !filePrefixIndicator
                    }
                  />
                );
              })}
            </Form>
          </div>
        </Stack>
        <Stack direction="horizontal" gap={2} className="ps-5 w-50">
          <div className="mt-2" style={{ width: "80%" }}>
          <SearchTable searchWord={searchWord} setSearchWord={setSearchWord}/>
          </div>
        </Stack>
      </Stack>
      {filePrefixIndicator && (
        <Stack direction="horizontal" className="my-2 mx-3">
          <Stack direction="horizontal" gap={2} className="ps-5 w-50">
            <div className="me-2">Enter DDL file prefix:</div>
            <div>
              <Form onSubmit={(event) => event.preventDefault()}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    onChange={(event) =>
                      setDDLPrefix(formatFilename(event.target.value))
                    }
                    value={DDLPrefix}
                    placeholder="Enter DDL file prefix or leave empty"
                  />
                </InputGroup>
              </Form>
            </div>
          </Stack>
          <Stack direction="horizontal" gap={2} className="ps-5 w-50">
            <div className="me-2">Enter Procedure file prefix:</div>
            <div>
              <Form onSubmit={(event) => event.preventDefault()}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    onChange={(event) =>
                      setProcedurePrefix(formatFilename(event.target.value))
                    }
                    value={procedurePrefix}
                    placeholder="Enter Procedure file prefix or leave empty"
                  />
                </InputGroup>
              </Form>
            </div>
          </Stack>
        </Stack>
      )}

      {origBtn && (
        <Stack direction="horizontal" className="my-2 mx-3">
          <Stack direction="horizontal" gap={2} className="ps-5 w-50">
            <div className="me-2">
              Do you want to include ORIG fields in the generated files :
            </div>
            <div>
              <Form onSubmit={(event) => event.preventDefault()}>
                {options.map((option, index) => {
                  return (
                    <Form.Check
                      inline
                      key={index}
                      type="radio"
                      id={`orig_field${option.label}`}
                      label={option.label}
                      onChange={handleOrigRadioChange}
                      value={option.value}
                      checked={origFieldInclude === option.value}

                    />
                  );
                })}
              </Form>
            </div>
          </Stack>
        </Stack>
      )}
      <div id="procedure-table" className="mx-5">
        <table>
          <thead>
            <tr>
              <th>
                <Checkbox
                  name="all"
                  onChange={handleAllCheckboxChange}
                  data-testid="testKey1-checkbox"
                  checked={checkAll}
                  sx={{
                    color: "#FFF",
                    "&.Mui-checked": {
                      color: "#FFF",
                    },
                  }}
                />
              </th>
              <th>Database</th>
              <th>Schema</th>
              <th>Table</th>
              <th>Created Objects</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {displayElements?.map((element) => (
              <tr key={element["key"]}>
                <td>
                  <Checkbox
                    id={element["key"]}
                    checked={element["checked"]}
                    onChange={handleCheckboxChange}
                    name={element["key"]}
                  />
                </td>
                <td>{element["database"]}</td>
                <td>{element["schema"]}</td>
                <td>{element["table"]}</td>
                <td>{element["label"]}</td>
                <td>{element["connectionType"]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VerificationPage;
