import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Link, List, ListItem, ListItemText, Button, Tooltip } from '@mui/material';
import { fileNameList } from '../Constant/FileNameList'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

const AccordionDetailsWithButton = ({ accordionData }) => {
    const { list } = accordionData;
    const zip = new JSZip()

    const handleClick = async () => {
        try {
            await Promise.all(fileNameList.map(async (fileName) => {
                const response = await fetch(fileName)

                const fileContent = await response.text();
                zip.file(fileName, fileContent)
            }))

            zip.generateAsync({ type: "blob" })
                .then((blob) => {
                    saveAs(blob, 'DVA-architecture.zip')
                })
        }

        catch (error) {
            console.error('Error downloading zip file:', error.message);
        }
    };
    return (
        <AccordionDetails>
            <Typography>
                {list && (<>
                    <List sx={{ listStyleType: 'disc', pl: 2 }}>
                        {list.map((item, index) => (
                            <ListItem key={index} sx={{ display: 'list-item' }}>
                                <ListItemText >
                                    <p className='bp'>{item.text}</p>
                                    {item.access && item.access.map((data, index) => (
                                        <div sx={{ pb: 2 }} className='subTitle'>
                                            <Typography variant="subtitle2">{data.heading}</Typography>
                                            <div>
                                                {data.para && data.para.map((content, index) => (
                                                    <Typography>
                                                        <p className='fontMatch'>{content.text}
                                                            {content.link && (<><Link  target="_blank" href={content.link.url}>{content.link.text}</Link></>)}
                                                        </p>
                                                    </Typography>
                                                ))}

                                            </div>
                                        </div>
                                    ))}
                                    {item.toolTip && (
                                        <Tooltip placement="top" title={item.toolTip} arrow>
                                            <Button sx={{ mb: 4, textTransform: 'none' }} variant="contained" onClick={handleClick}>{item.downloadButtonTxt}</Button>
                                        </Tooltip>)}
                                    {item.para && item.para.map((paragraph) => (
                                        <p className='fontMatch'>{paragraph.text}
                                            {paragraph.link && (<><Link target="_blank" href={paragraph.link.url}>{paragraph.link.text}</Link></>)}</p>
                                    ))}

                                    {item.numberList && (<><ol>
                                        {item.numberList.map((listItem) => (
                                            <li className='fontMatch'>{listItem}</li>
                                        ))}
                                    </ol></>)}
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List></>)}
            </Typography>
        </AccordionDetails>
    )
}

export default AccordionDetailsWithButton