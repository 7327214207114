import React from 'react';
import { useModalContext } from '../../../Context/ModalContext';
import { Handle } from 'react-flow-renderer';
import './CustomNodesStyle.css';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { usePlaygroundContext } from '../../../Context/PlaygroundContext';
import Tooltip from "@mui/material/Tooltip"

const RefNode = (node) => {
  const { enableNodeSettingsModalShow, setNodeSettingsModalCurrentNode } = useModalContext()
  const { onElementsRemove } = usePlaygroundContext()
  const { label } = node.data

  const onClick = () => {
    setNodeSettingsModalCurrentNode(node)
    enableNodeSettingsModalShow(node.id)
  }

  const onDelete = () => {
    onElementsRemove([node])
  }

  return (
    <div className="refNode">
      <Handle
        type="target"
        position='top'
        style={{ background: '#555' }}
      />
      <div className="py-1 px-2" >
        {label}
        <span 
          className="ms-2" 
          onClick={onClick} 
          style={{ cursor: 'pointer' }}
        >
          <Tooltip 
            title="Rename REF node" 
            placement="bottom-end" 
            arrow 
          >
            <DriveFileRenameOutlineRoundedIcon style={{ fontSize: 'medium', marginBottom: "0.25rem" }} />
          </Tooltip>
        </span>
        {node.selected && (
          <span className="ms-2" onClick={onDelete} style={{ cursor: 'pointer' }}>
            <Tooltip 
              title="Delete node along with its dependents" 
              placement="bottom-start" 
              arrow 
            >
              <DeleteForeverIcon style={{ fontSize: 'large', marginBottom: "0.175rem" }} />
            </Tooltip>
          </span>
          )
        }
      </div>
    </div>
  );
}

export default RefNode;