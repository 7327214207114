import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';

const DatabaseContent = ({ database, account, totalPages, getData, currentPage, setCurrentPage, limit, selectedValue }) => {
 const getDesignWithDB = (data) =>{
      getData(account, 1, limit, selectedValue, data);
  }
  return (
    <>{(database?.length === 0) ? 
          <div className="main-content-top-no-design-text">There are no matches for your search</div>
        :
    <div className="card_design">
      {database?.map(data => {
        return (
          <Card variant="outlined" className="card_display"
            role='button' onClick={() => getDesignWithDB(data)}>
            <div className="card_display_database_name">
              {data.DATABASE}
            </div>
            {(data.SIZE > 1) ? (<div className="card_display_design_count">
              {data.SIZE} Designs
            </div>) : (<div className="card_display_design_count">
              {data.SIZE} Design
            </div>)}
          </Card>
        )
      })}
    </div>
    }</>
  );
};

export default DatabaseContent;

