import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useSocketioContext } from "../Context/SocketioContext";

const FilterSelectDropdown = ({ account, getData, currentPage, limit, selectedValue, setSelectedValue, currentDB, searchResult}) => {
  const { socket } = useSocketioContext();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (account !== "") {
      getData(account, currentPage, limit, selectedValue, currentDB, searchResult);
      setLoading(false);
    }
  }, [selectedValue])
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  }

  return (
    <div className="filterWrapper">
      <Form.Group className="filterFormGroup" controlId="formFilter">
        <Form.Label>Sort by: </Form.Label>
        <Form.Select
          defaultValue=""
          value={selectedValue}
          aria-label="Select"
          onChange={handleChange}
          disabled={loading}
        >
          <option value="newest">Newest</option>
          <option value="oldest">Oldest</option>
        </Form.Select>
      </Form.Group>
    </div>
  );
};

export default FilterSelectDropdown;
