// This is the Main page of the Application, it will be displayed once the user logs in via SSO
import { useEffect, useState } from 'react'
import MenuSideBar from "../Components/MenuSideBar"
import MainTopBar from '../Components/MainTopBar'
import MainContent from '../Components/MainContent'
import { HOME } from '../Types/MenuTypes'
import { useAuth } from '../Context/AuthContext'
import CustomAlert from '../Components/CustomAlert'
import { useModalContext } from '../Context/ModalContext'
import { getLocalStorageData } from '../Logic/useLocalStorage'
import { useSocketioContext } from '../Context/SocketioContext'

const MainPage = () => {
  // State for Menu options 
  const [ menuState, setMenuState ] = useState(HOME)
  const { user, userRegistered, setUserRegistered } = useAuth()
  const { userSettingsModalShow } = useModalContext()
  const { socket } = useSocketioContext()
  
  useEffect(async () => {
    if(socket !== null) {
      if(!userRegistered) {
        //Socket event 'addUser' sent to server with logged in user details to be stored in database
        socket.emit('addUser', {
          userId: user?.attributes?.name,
          familyName: user?.attributes?.family_name,
          givenName: user?.attributes?.given_name,
          email: user?.attributes?.email
        })
        const userSettings = getLocalStorageData(user?.attributes?.name)
        if (userSettings === null) {
          userSettingsModalShow()
        }
      }
      //Socket event 'userRegistered' sent as an acknowledgement from server to client
      socket.on('userRegistered', (payload) => {
        setUserRegistered(true)
      })
    }
  }, [socket])

  return (
    <div className="row vh-100 g-0">
      {/* Sidebar Menu in the left pane of the Main page */}
      <div className="col col-sm-2 col-md-3 flex-shrink-0">
        <MenuSideBar menuState={menuState} setMenuState={setMenuState} />
      </div>

      {/* Content in the right pane of the Main page */}
      <div className="col col-sm-12 col-md-9 flex-shrink-1 main_page_wrapper">
        <MainTopBar selectedMenu={menuState} />
        <CustomAlert />
        <MainContent selectedMenu={menuState} />
      </div>
    </div>
  )
}

export default MainPage