import React from "react";
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';

const SimmerUI = () => {
  return (
    <div className="card_wrapper"> {Array.from({ length: 3 }).map((e, i) => {
        return (
          <Card variant="outlined" className="simmer_card card_display">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Card>
        )
      })}</div>
  );
};

export default SimmerUI;