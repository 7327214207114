import React, { useState, useEffect } from "react";
import CustomAccordion from "../Components/CustomAccordion";
import { useSocketioContext } from "../Context/SocketioContext";

const BacklogPage = () => {
  const [prioritiesData, setPrioritiesData] = useState([])
  const [releaseLogData, setReleaseLogData] = useState([])
  const { socket } = useSocketioContext()

  useEffect(() => {
    if(socket !== null) {
      socket.emit('getVersionDetails', (versionDetails) => {
        const { priorities, releaseLog } = versionDetails
        setPrioritiesData(priorities)
        setReleaseLogData(releaseLog)
      })
    }
  }, [socket])

    return (
      <div>
        <CustomAccordion 
          summaryComponent={
            <p className='fs-6 fw-bold'>
                Priorities:
              </p>
          }
          detailsComponent={
            <div className="mt-2">       
              {prioritiesData.map((item) => (
                <p className="fs-6 mx-3"> {item}</p>
              ))
              }
            </div>
          }
        />
        {releaseLogData.map((item) => (
          <CustomAccordion 
            summaryComponent={
              <p className='fs-6 fw-bold'>
                Version: {item.version}, Release date: {item.releaseDate}
              </p>
            }
            detailsComponent={
              <div className="mt-2">       
                {item.newFeatures.map((feature) => (
                    feature.description !== "" ?
                    (
                      <CustomAccordion 
                        summaryComponent={
                          <p className="fs-6 fw-bold"> {feature.title}</p>
                        }
                        detailsComponent={
                          <p className="fs-6"> {feature.description}</p>
                        }
                      />
                    ) : (
                      <p className="fs-6 mx-3"> {feature.title}</p>
                    )
                ))
                }
              </div>
            }
          />
        ))}
      </div>
    )
}

export default BacklogPage;