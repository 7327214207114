import React, { useState, useRef, useEffect } from 'react';
import { useReactFlowContext } from '../../Context/reactFlowContext';
import { useAlertContext } from '../../Context/AlertContext';
import { usePlaygroundContext } from '../../Context/PlaygroundContext';
import { formatName } from '../../Logic/cleanNames'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Controls,
  Background,
  MiniMap,
  isNode,
} from 'react-flow-renderer';
import DropdownSideBar from '../DropdownSideBar';
import HubNode from './CustomNodes/HubNode';
import RefNode from './CustomNodes/RefNode';
import XRefNode from './CustomNodes/XRefNode';
import LinkNode from './CustomNodes/LinkNode';
import XLinkNodeFull from './CustomNodes/XLinkNodeFull';
import XLinkNodeDelta from './CustomNodes/XLinkNodeDelta';
import SatNode from './CustomNodes/SatNode';
import LSatNode from './CustomNodes/LSatNode';
import {
  HUB_SATELLITE,
  LINK_SATELLITE,
  REF_SATELLITE,
  XREF,
  XLINK_DELTA,
  XLINK_FULL,
  HUB_SAT_LINK_LSAT,
  DATA,
  SCHEMA,
  LABEL,
  HUB_CONNECTIONS,
  OVERLOAD_SOURCE_TABLES_OBJECT,
  DRAGGED_CONNECTION,
  CONNECTION_TYPE,
} from './CustomNodes/NodeLinkTypes';
import NodeSettingsModal from '../NodeSettingsModal';
import { v4 as uuidv4 } from 'uuid';
import { useDesignsContext } from '../../Context/DesignsContext';
import useElementCopy from '../../Logic/useElementCopy';
import { useTranslation } from 'react-i18next';

const nodeTypes = {
  hubnode: HubNode,
  refnode: RefNode,
  xrefnode: XRefNode,
  xlinknodefull: XLinkNodeFull,
  xlinknodedelta: XLinkNodeDelta,
  linknode: LinkNode,
  satnode: SatNode,
  lsatnode: LSatNode
}
const updateDelay = 250

const PlayGround = () => {
  const { reactFlowInstance, setReactFlowInstance, getElements } = useReactFlowContext()
  const { elements, setElements, onElementsRemove, handleLinkStatus } = usePlaygroundContext()
  const { currentDesign, updateDesign } = useDesignsContext()
  const { addAlert } = useAlertContext()
  const [menuCollapse, setMenuCollapse] = useState(false)
  const [selectedElements, setSelectedElements] = useState([])
  const reactFlowWrapper = useRef(null);
  const {t} = useTranslation();
  
  useElementCopy(
    elements,
    setElements,
    selectedElements,
    setSelectedElements,
    updateDesign,
    updateDelay
  )

  useEffect(() => {
    if(!currentDesign.updatedByDesign) {
      const designData = currentDesign?.data?.elements || []
      setElements([ ...designData ])
    }
  }, [currentDesign.lastSaveTimeStamp, currentDesign.updatedByDesign])

  const onConnect = async (params) => {
    const { source, target } = params
    const nodeTypesPattern = /^HUB|^LINK|^XLINK_DELTA|^XLINK_FULL|^SAT|^MSAT|^LSAT|^REF/
    const sourceNodeType = source.match(nodeTypesPattern)[0]
    const targetNodeType = target.match(nodeTypesPattern)[0]
    switch(true) {
      case (['LINK', 'XLINK_DELTA', 'XLINK_FULL'].includes(sourceNodeType) && targetNodeType === 'HUB'):
        setElements((els) => addEdge(params, els))
        await handleLinkStatus(source, target, 'add')
        setTimeout(() => {
          updateDesign()
        }, updateDelay)
        break
      case (['SAT', 'MSAT', 'LSAT'].includes(sourceNodeType) && targetNodeType === 'REF'):
        setElements((els) => addEdge(params, els))
        setTimeout(() => {
          updateDesign()
        }, updateDelay)
        break
      default:
        addAlert({ msg: t("CLIENT_ERRORS.CONNECTION_NOT_ALLOWED", {sourceNodeType,targetNodeType}), isDismissible: false })
    }
  };


  const onLoad = (_reactFlowInstance) => {
    setReactFlowInstance(_reactFlowInstance);
    if(currentDesign.data?.elements?.length > 0 ) {
      setElements(currentDesign?.data?.elements || []);
    } else {
      setElements([])
    }
  }

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onNodeDragStop = (event, node) => {
    const elementsList = elements
    elementsList.forEach(nodeItem => {
      if (nodeItem.id === node.id) {
        nodeItem.position.x = event.layerX - event.offsetX - 4.9
        nodeItem.position.y = event.layerY - event.offsetY - 2.9
      }
    })
    setElements([...elementsList])
    updateDesign()
  }

  const droppedOnNode = (nodePosition, nodeType) => {
    return getElements().find(element => {
      if (isNode(element) && element.type === nodeType) {
        const x1 = element.position.x
        const y1 = element.position.y
        const htmlElement = document.querySelector(`div.react-flow__node.react-flow__node-${nodeType}[data-id=${element.id}]`)
        const x2 = x1 + htmlElement.offsetWidth
        const y2 = y1 + htmlElement.offsetHeight
        return (nodePosition.x > x1 && nodePosition.x < x2 && nodePosition.y > y1 && nodePosition.y < y2)
      } else {
        return false
      }
    })
  }

  const overloadSatellite = (overloadNode, table) => {
    setElements((prevElements) => {
      const elementsList = prevElements
      elementsList.forEach(nodeItem => {
        if (nodeItem.id === overloadNode.id) {
          if (nodeItem[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] !== undefined) {
            if (nodeItem[DATA][OVERLOAD_SOURCE_TABLES_OBJECT][table] === undefined) {
              const object = { ...nodeItem[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] }
              object[table] = ''
              nodeItem[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] = { ...object }
            }
          } else {
            nodeItem[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] = {}
            nodeItem[DATA][OVERLOAD_SOURCE_TABLES_OBJECT][table] = ''
          }
        }
      })
      return [...elementsList]
    })
    setTimeout(() => {
      updateDesign()
    }, updateDelay)
  }

  const checkNodeExist = nodeValue => {
    let nodeExist = false;
    setElements(prevElements => {
      const elementsList = [ ...prevElements ]
      elementsList.forEach(nodeItem => {
        if (isNode(nodeItem)) {
          if (nodeItem.data.label === nodeValue) {
            nodeExist = true
          }
        }
      })
      return prevElements
    })
    return nodeExist
  }

  const onDrop = (event) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const nodeData = JSON.parse(event.dataTransfer.getData('application/reactflow/nodeData'));
    const { schema, table, nodeLink, nodeName } = nodeData;
    const database = nodeData.database;
    let hubNodeType, hubsatNodeType, inputNodeType, satelliteNodeType, hubNodeId, hubsatNodeId, inputNodeId, satelliteNodeId, inputNodeValue, satelliteNodeValue, hubNodeValue, hubSatNodeValue, inputNodePosition, satelliteNodePosition, hubNodePosition, hubSatNodePosition, newNodes, draggedConnection, droppedOnExistingNode, droppedOnExistingLsatNode, targetLinkStatusValid, targetLinkStatusValidType, edgeNodeId;

    switch (nodeLink) {
      case HUB_SATELLITE:
        inputNodeType = 'hubnode'
        satelliteNodeType = 'satnode'

        inputNodeId = `HUB_${uuidv4()}`
        inputNodeValue = formatName(`HUB_${nodeName.toUpperCase()}`)

        satelliteNodeId = `SAT_${uuidv4()}`
        satelliteNodeValue = formatName(`SAT_${table.toUpperCase()}`)

        edgeNodeId = `edge_HUB_${uuidv4()}_SAT_${uuidv4()}`

        draggedConnection = {
          connectionType: 'HUB||SAT',
          connectedEntities: [inputNodeId, satelliteNodeId]
        }
        inputNodePosition = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: (event.clientY - reactFlowBounds.top) + 100,
        });
        satelliteNodePosition = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: (event.clientY - reactFlowBounds.top),
        });
        if (checkNodeExist(inputNodeValue)) {
          if (!checkNodeExist(satelliteNodeValue)) {
            const droppedOnExistingNode = droppedOnNode(satelliteNodePosition, 'satnode')
            if (droppedOnExistingNode !== undefined) {
              if (droppedOnExistingNode[DATA][SCHEMA] !== schema) {
                addAlert({ msg: t("CLIENT_ERRORS.SELECT_TO_OVERLOAD", {schema: droppedOnExistingNode[DATA][SCHEMA], label: droppedOnExistingNode[DATA][LABEL]}), isDismissible: true })
              } else overloadSatellite(droppedOnExistingNode, table)
            } else {
              // find the previous hub
              const hub = getElements().find(x => x[DATA][LABEL] === inputNodeValue)
              draggedConnection = {
                connectionType: 'HUB||SAT',
                connectedEntities: [hub.id, satelliteNodeId]
              }
              newNodes = [
                {
                  id: satelliteNodeId,
                  type: satelliteNodeType,
                  position: satelliteNodePosition,
                  data: {
                    database,
                    schema,
                    table,
                    label: satelliteNodeValue,
                    draggedConnection,
                  },
                }, {
                  id: edgeNodeId,
                  source: ((inputNodeType === 'hubnode') ? satelliteNodeId : hub.id),
                  target: ((inputNodeType === 'hubnode') ? hub.id : satelliteNodeId),
                  type: 'default',
                }
              ];
              setElements(prevNodes => [...prevNodes, ...newNodes]);
              setTimeout(() => {
                updateDesign()
              }, updateDelay)
            }
          } else {
            addAlert({ msg: t('CLIENT_ERRORS.NODE_ALREADY_EXIST'), isDismissible: false })  
          }
        } else {
          newNodes = [
            {
              id: inputNodeId,
              type: inputNodeType,
              position: inputNodePosition,
              data: {
                database,
                schema,
                table,
                originalLabel: inputNodeValue,
                label: inputNodeValue,
                linkStatus: true,
                draggedConnection,
              },
            }, {
              id: satelliteNodeId,
              type: satelliteNodeType,
              position: satelliteNodePosition,
              data: {
                database,
                schema,
                table,
                satellite: inputNodeValue,
                label: satelliteNodeValue,
                draggedConnection,
              },
            }, {
              id: edgeNodeId,
              source: ((inputNodeType === 'hubnode') ? satelliteNodeId : inputNodeId),
              target: ((inputNodeType === 'hubnode') ? inputNodeId : satelliteNodeId),
              type: 'default',
            }
          ];
          setElements(prevNodes => [...prevNodes, ...newNodes]);
          setTimeout(() => {
            updateDesign()
          }, updateDelay)
        }
        break;

      case REF_SATELLITE:
        inputNodeType = 'refnode'
        satelliteNodeType = 'satnode'

        inputNodeId = `REF_${uuidv4()}`
        inputNodeValue = formatName(`REF_${nodeName.toUpperCase()}`)

        satelliteNodeId = `SAT_${uuidv4()}`
        satelliteNodeValue = formatName(`SAT_${table.toUpperCase()}`)

        edgeNodeId = `edge_REF_${uuidv4()}_SAT_${uuidv4()}`

        draggedConnection = {
          connectionType: 'REF||SAT',
          connectedEntities: [inputNodeId, satelliteNodeId]
        }

        inputNodePosition = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: (event.clientY - reactFlowBounds.top) + 100,
        });
        satelliteNodePosition = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: (event.clientY - reactFlowBounds.top),
        });

        if (checkNodeExist(inputNodeValue)) {
          if (!checkNodeExist(satelliteNodeValue)) {
            const ref = getElements().find(x => x[DATA][LABEL] === inputNodeValue)
            draggedConnection = {
              connectionType: 'REF||SAT',
              connectedEntities: [ref.id, satelliteNodeId]
            }
            newNodes = [
              {
                id: satelliteNodeId,
                type: satelliteNodeType,
                position: satelliteNodePosition,
                data: {
                  database,
                  schema,
                  table,
                  label: satelliteNodeValue,
                  draggedConnection,
                },
              }, {
                id: edgeNodeId,
                source: ((inputNodeType === 'refnode') ? satelliteNodeId : ref.id),
                target: ((inputNodeType === 'refnode') ? ref.id : satelliteNodeId),
                type: 'default',
              }
            ];
            setElements(prevNodes => [...prevNodes, ...newNodes]);
            setTimeout(() => {
              updateDesign()
            }, updateDelay)
          } else {
            addAlert({ msg: t('CLIENT_ERRORS.NODE_ALREADY_EXIST'), isDismissible: false })
          }
        } else {
          newNodes = [
            {
              id: inputNodeId,
              type: inputNodeType,
              position: inputNodePosition,
              data: {
                database,
                schema,
                table,
                originalLabel: inputNodeValue,
                label: inputNodeValue,
                linkStatus: true,
                draggedConnection,
              },
            }, {
              id: satelliteNodeId,
              type: satelliteNodeType,
              position: satelliteNodePosition,
              data: {
                database,
                schema,
                table,
                satellite: inputNodeValue,
                label: satelliteNodeValue,
                draggedConnection,
              },
            }, {
              id: edgeNodeId,
              source: ((inputNodeType === 'refnode') ? satelliteNodeId : inputNodeId),
              target: ((inputNodeType === 'refnode') ? inputNodeId : satelliteNodeId),
              type: 'default',
            }
          ];
          setElements(prevNodes => [...prevNodes, ...newNodes]);
          setTimeout(() => {
            updateDesign()
          }, updateDelay)
        }
        break;

      case XREF:
        inputNodeType = 'xrefnode'

        inputNodeId = `XREF_${uuidv4()}`

        inputNodeValue = formatName(`XREF_${nodeName.toUpperCase()}`)

        draggedConnection = {
          connectionType: 'XREF||FULL',
          connectedEntities: [inputNodeId]
        }

        inputNodePosition = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: (event.clientY - reactFlowBounds.top),
        });

        if (!checkNodeExist(inputNodeValue)) {
          newNodes = [
            {
              id: inputNodeId,
              type: inputNodeType,
              position: inputNodePosition,
              connectable: false,
              data: {
                database,
                schema,
                table,
                label: inputNodeValue,
                draggedConnection,
              },
            }
          ];
          setElements(prevNodes => [...prevNodes, ...newNodes]);
          setTimeout(() => {
            updateDesign()
          }, updateDelay)
        } else {
          addAlert({ msg: t('CLIENT_ERRORS.NODE_ALREADY_EXIST'), isDismissible: false })
        }
        break;

      case LINK_SATELLITE:
        inputNodeType = 'linknode'
        satelliteNodeType = 'lsatnode'

        satelliteNodeId = `LSAT_${uuidv4()}`
        satelliteNodeValue = formatName(`LSAT_${table.toUpperCase()}`)

        edgeNodeId = `edge_LINK_${uuidv4()}_LSAT_${uuidv4()}`

        inputNodePosition = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: (event.clientY - reactFlowBounds.top),
        });
        satelliteNodePosition = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: (event.clientY - reactFlowBounds.top) + 100,
        });

        droppedOnExistingNode = droppedOnNode(inputNodePosition, 'linknode')
        droppedOnExistingLsatNode = droppedOnNode(inputNodePosition, 'lsatnode')

        if (droppedOnExistingLsatNode !== undefined) {
          const element = getElements().find(x => x.id === droppedOnExistingLsatNode[DATA]["draggedConnection"]["connectedEntities"][0])

          switch (true) {
            case (element[DATA][DRAGGED_CONNECTION][CONNECTION_TYPE] === "HUB||SAT||LINK||LSAT"):
              targetLinkStatusValidType = false
              targetLinkStatusValid = true
              break;
            case (element[DATA][HUB_CONNECTIONS]["hubOrder"].length > 1):
              targetLinkStatusValid = true
              targetLinkStatusValidType = true
              break;
            default:
              targetLinkStatusValidType = true
              targetLinkStatusValid = false
          }
        }

        switch (true) {
          // if dropped on LINK node
          case (droppedOnExistingNode !== undefined):
            inputNodeId = droppedOnExistingNode.id
            draggedConnection = {
              connectionType: 'LINK||LSAT',
              connectedEntities: [inputNodeId, satelliteNodeId]
            }
            newNodes = [
              {
                id: satelliteNodeId,
                type: satelliteNodeType,
                position: satelliteNodePosition,
                data: {
                  database,
                  schema,
                  table,
                  label: satelliteNodeValue,
                  draggedConnection,
                  enableNodeSettings: droppedOnExistingNode[DATA][HUB_CONNECTIONS]["hubOrder"].length > 1 ? true : false
                },
              }, {
                id: edgeNodeId,
                source: inputNodeId,
                target: satelliteNodeId,
                type: 'default',
              }
            ]
            setElements(prevNodes => [...prevNodes, ...newNodes])
            setTimeout(() => {
              updateDesign()
            }, updateDelay)
            break;

          // if dropped on LSAT node
          case (droppedOnExistingLsatNode !== undefined):

            switch (true) {
              // if LINK of the LSAT dropped on not connected
              case !targetLinkStatusValid:
                addAlert({ msg: t('CLIENT_ERRORS.TARGET_LINK_NOT_CONNECTED'), isDismissible: true })
                break;
              // If lsat part of hub||sat||link||lsat  
              case !targetLinkStatusValidType:
                addAlert({ msg: t('CLIENT_ERRORS.OVERLOAD_LSAT'), isDismissible: true })
                break;
              // if the schema is different than the node
              case (droppedOnExistingLsatNode[DATA][SCHEMA] !== schema):
                addAlert({ msg: t("CLIENT_ERRORS.SELECT_TO_OVERLOAD", {schema: droppedOnExistingNode[DATA][SCHEMA], label: droppedOnExistingNode[DATA][LABEL]}), isDismissible: true })
                break;
              // if dropped on the same LSAT as the dragged table
              case checkNodeExist(satelliteNodeValue):
                addAlert({ msg: t('CLIENT_ERRORS.SAME_LSAT'), isDismissible: true })
                break;
              default:
                // overload
                overloadSatellite(droppedOnExistingLsatNode, table)
            }
            break;

          // not dropped on either
          default:
            inputNodeId = `LINK_${uuidv4()}`
            inputNodeValue = 'LINK'
            draggedConnection = {
              connectionType: 'LINK||LSAT',
              connectedEntities: [inputNodeId, satelliteNodeId]
            }
            newNodes = [
              {
                id: inputNodeId,
                type: inputNodeType,
                position: inputNodePosition,
                data: {
                  database,
                  schema,
                  table,
                  label: inputNodeValue,
                  linkStatus: true,
                  draggedConnection,
                  hubConnections: { "hubOrder": [] },
                },
              }, {
                id: satelliteNodeId,
                type: satelliteNodeType,
                position: satelliteNodePosition,
                data: {
                  database,
                  schema,
                  table,
                  label: satelliteNodeValue,
                  draggedConnection,
                  enableNodeSettings: false
                },
              }, {
                id: edgeNodeId,
                source: inputNodeId,
                target: satelliteNodeId,
                type: 'default',
              }
            ];
            setElements(prevNodes => [...prevNodes, ...newNodes])
            setTimeout(() => {
              updateDesign()
            }, updateDelay)
            break;
        }

        break;

      case XLINK_DELTA:

        inputNodeType = 'xlinknodedelta'
        inputNodeValue = `XLINK`

        inputNodeId = `XLINK_DELTA_${uuidv4()}`

        draggedConnection = {
          connectionType: 'XLINK||DELTA',
          connectedEntities: [inputNodeId]
        }
        inputNodePosition = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: (event.clientY - reactFlowBounds.top),
        });
        newNodes = [
          {
            id: inputNodeId,
            type: inputNodeType,
            position: inputNodePosition,
            data: {
              database,
              schema,
              table,
              label: inputNodeValue,
              enableNodeSettings: false,
              linkStatus: true,
              draggedConnection,
              hubConnections: { "hubOrder": [] },
            },
          }
        ];
        setElements(prevNodes => [...prevNodes, ...newNodes])
        setTimeout(() => {
          updateDesign()
        }, updateDelay)
        break;

      case XLINK_FULL:
        inputNodeType = 'xlinknodefull'
        inputNodeValue = `XLINK`
        inputNodeId = `XLINK_FULL_${uuidv4()}`
        draggedConnection = {
          connectionType: 'XLINK||FULL',
          connectedEntities: [inputNodeId]
        }
        inputNodePosition = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: (event.clientY - reactFlowBounds.top),
        });
        newNodes = [
          {
            id: inputNodeId,
            type: inputNodeType,
            position: inputNodePosition,
            data: {
              database,
              schema,
              table,
              label: inputNodeValue,
              enableNodeSettings: false,
              linkStatus: true,
              draggedConnection,
              hubConnections: { "hubOrder": [] },
            },
          }
        ];
        setElements(prevNodes => [...prevNodes, ...newNodes])
        setTimeout(() => {
          updateDesign()
        }, updateDelay)
        break;

      case HUB_SAT_LINK_LSAT:
        hubNodeType = 'hubnode'
        hubsatNodeType = 'satnode'
        inputNodeType = 'linknode'
        satelliteNodeType = 'lsatnode'

        hubNodeId = `HUB_${uuidv4()}`
        hubsatNodeId = `SAT_${uuidv4()}`
        inputNodeId = `LINK_${uuidv4()}`
        satelliteNodeId = `LSAT_${uuidv4()}`

        hubNodeValue = formatName(`HUB_${nodeName.toUpperCase()}`)
        hubSatNodeValue = formatName(`SAT_${table.toUpperCase()}`)
        inputNodeValue = formatName(`LINK_${nodeName.toUpperCase()}`)
        satelliteNodeValue = formatName(`LSAT_${table.toUpperCase()}`)

        draggedConnection = {
          connectionType: 'HUB||SAT||LINK||LSAT',
          connectedEntities: [hubNodeId, hubsatNodeId, inputNodeId, satelliteNodeId]
        }

        hubNodePosition = reactFlowInstance.project({
          x: (event.clientX - reactFlowBounds.left) - 200,
          y: (event.clientY - reactFlowBounds.top) + 100,
        });
        hubSatNodePosition = reactFlowInstance.project({
          x: (event.clientX - reactFlowBounds.left) - 200,
          y: (event.clientY - reactFlowBounds.top) - 100,
        });
        inputNodePosition = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: (event.clientY - reactFlowBounds.top),
        });
        satelliteNodePosition = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: (event.clientY - reactFlowBounds.top) + 100,
        });

        const hubConnectionsInnitial = {}
        hubConnectionsInnitial[hubNodeId] = hubNodeValue.replace(/^HUB_/, '')
        hubConnectionsInnitial["hubOrder"] = [hubNodeId]

        newNodes = [
          // HUB node
          {
            id: hubNodeId,
            type: hubNodeType,
            position: hubNodePosition,
            data: {
              database,
              schema,
              table,
              label: hubNodeValue,
              linkStatus: true,
              draggedConnection
            },
          },
          // SAT node
          {
            id: hubsatNodeId,
            type: hubsatNodeType,
            position: hubSatNodePosition,
            data: {
              database,
              schema,
              table,
              satellite: hubNodeValue,
              label: hubSatNodeValue,
              draggedConnection
            },
          },
          // LINK node
          {
            id: inputNodeId,
            type: inputNodeType,
            position: inputNodePosition,
            data: {
              database,
              schema,
              table,
              label: inputNodeValue,
              linkStatus: true,
              draggedConnection,
              hubConnections: hubConnectionsInnitial,
            },
          },
          // LSAT node
          {
            id: satelliteNodeId,
            type: satelliteNodeType,
            position: satelliteNodePosition,
            data: {
              database,
              schema,
              table,
              label: satelliteNodeValue,
              draggedConnection,
              enableNodeSettings: false
            },
          }
          ,
          // Connection HUB - SAT
          {
            id: `edge_HUB_${uuidv4()}_SAT_${uuidv4()}`,
            source: ((inputNodeType === 'hubnode') ? hubNodeId : hubsatNodeId),
            target: ((inputNodeType === 'hubnode') ? hubsatNodeId : hubNodeId),
            type: 'default',
          },
          // Connection LINK - LSAT
          {
            id: `edge_LINK_${uuidv4()}_LSAT_${uuidv4()}`,
            source: ((inputNodeType === 'linknode') ? inputNodeId : satelliteNodeId),
            target: ((inputNodeType === 'linknode') ? satelliteNodeId : inputNodeId),
            type: 'default',
          }
          ,
          // Connection HUB - LINK
          {
            id: `edge_HUB_${uuidv4()}_LINK_${uuidv4()}`,
            source: ((inputNodeType === 'linknode') ? inputNodeId : hubNodeId),
            target: ((inputNodeType === 'linknode') ? hubNodeId : inputNodeId),
            type: 'default',
          }
        ];
        setElements(prevNodes => [...prevNodes, ...newNodes])
        setTimeout(() => {
          updateDesign()
        }, updateDelay)
        break;

      default:
        break;

    }
  };

  const handleSelectionChange = (elements) => {
    setSelectedElements(elements || [])
  }

  return (
    <div className="dndflow h-100">
      <NodeSettingsModal elements={elements} setElements={setElements} />
      <ReactFlowProvider>
        <div className="row gx-0 h-100">
          {/* <div className="col-2 bg-light p-1 border"> */}

          {!menuCollapse && (
            <div
              className='bg-light border h-100'
              style={{ width: '15%' }}
            >
              <DropdownSideBar               
                reactFlowWrapper={reactFlowWrapper.current}
                menuCollapse={menuCollapse}
                handleMenuCollapse={setMenuCollapse}/>
            </div>)
          }
          <div
            className='bg-light border h-100'
            style={{ width: menuCollapse ? '100%' : '85%'}}
          >
            <div className="reactflow-wrapper h-100" ref={reactFlowWrapper}>
              <ReactFlow
                className="w-100 h-100"
                elements={elements}
                nodeTypes={nodeTypes}
                onConnect={onConnect}
                connectionLineStyle={{ stroke: "#ddd", strokeWidth: 2 }}
                onElementsRemove={onElementsRemove}
                onLoad={onLoad}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onNodeDragStop={onNodeDragStop}
                onSelectionChange={handleSelectionChange}
                handleLinkStatus={handleLinkStatus}
                snapToGrid={true}
                snapGrid={[16, 16]}
              >
                {menuCollapse ? (
                  <span
                    style={{
                      position: 'absolute',
                      top: '5px',
                      left: 0,
                      zIndex: 4,
                      cursor: 'pointer'
                    }}
                    onClick={() => setMenuCollapse(false)}
                  >
                    <ArrowRightRoundedIcon 
                      sx={{ fontSize: "2.5rem"}} 
                    />
                  </span>) : (
                  <span
                    style={{
                      position: 'absolute',
                      top: '5px',
                      left: 0,
                      zIndex: 4,
                      cursor: 'pointer'
                    }}
                    onClick={() => setMenuCollapse(true)}
                  >
                    <ArrowLeftRoundedIcon 
                      sx={{ fontSize: "2.5rem"}} 
                    />
                  </span>)
                }
                <MiniMap
                  nodeColor={(node) => {
                    switch (node.type) {
                      case 'hubnode':
                        return '#047dbb';
                      case 'refnode':
                        return '#CA2935';
                      case 'xrefnode':
                        return '#CA2935';
                      case 'linknode':
                        return '#7bb746';
                      case 'xlinknodedelta':
                        return '#F2F5C8';
                      case 'xlinknodefull':
                        return '#E8E8A6';
                      case 'satnode':
                        return '#f19d32';
                      case 'lsatnode':
                        return '#f19d32'
                      default:
                        return '#eee';
                    }
                  }}
                  nodeStrokeWidth={3}
                />
                <Controls />
                <Background
                  variant="dots"
                  gap={12}
                  size={0.5}
                  color={'#aaa'}
                />
              </ReactFlow>
            </div>
          </div>
        </div>
      </ReactFlowProvider>
    </div>
  );
};

export default PlayGround;