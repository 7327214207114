import Dropdown from "react-bootstrap/Dropdown";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import React, { useState, useEffect, memo } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useDesignsContext } from "../Context/DesignsContext";

const CustomDropdown = ({
  needToBeAuthorized = true,
  dropdownList,
  dropdownName,
  handleSelection,
  selectedValue,
  filterable,
  clipLength,
  freeze,
  customClassname = "",
}) => {
  const [filterInput, setFilterInput] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([...dropdownList]);
  const [selectedOption, setSelectedOption] = useState(dropdownName);
  const { currentDesign } = useDesignsContext();

  const filterList = (event) => {
    setFilterInput(event.target.value);
  };

  useEffect(() => {
    if (filterInput !== "") {
      const newDropdownOptions = dropdownList.filter((listItem) => {
        if (listItem === null || listItem === undefined) return false;
        return listItem.toUpperCase().includes(filterInput.toUpperCase());
      });
      setFilteredOptions([...newDropdownOptions]);
    } else {
      setFilteredOptions([...dropdownList]);
    }
  }, [filterInput, dropdownList]);

  useEffect(() => {
    setFilterInput("");
    setSelectedOption(dropdownName);
  }, [dropdownList]);

  const onClick = (event) => {
    event.preventDefault();
    const optionValue = event.target.textContent;
    setSelectedOption(optionValue);
    handleSelection(optionValue);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        id={dropdownName}
        variant="primary"
        className={classNames("w-100", customClassname)}
        disabled={
          freeze === true ||
          (needToBeAuthorized && currentDesign.authorized === false)
        }
      >
        {selectedValue !== undefined && selectedValue !== "" ? (
          selectedValue.length > clipLength ? (
            <Tooltip title={selectedValue} placement="bottom-start" arrow>
              <span>{`${selectedValue.substring(0, clipLength)}...`}</span>
            </Tooltip>
          ) : (
            // <abbr title={selectedValue}>{`${selectedValue.substring(0, clipLength)}...`}</abbr>
            selectedValue
          )
        ) : selectedOption.length > clipLength ? (
          <Tooltip
            title={
              selectedOption === dropdownName
                ? `Select ${dropdownName}`
                : selectedOption
            }
            placement="bottom-start"
            arrow
          >
            <span>{`${selectedOption.substring(0, clipLength)}...`}</span>
          </Tooltip>
        ) : (
          // <abbr title={(selectedOption === dropdownName) ? `Select ${dropdownName}` : selectedOption}>
          //   {`${selectedOption.substring(0, clipLength)}...`}
          // </abbr>
          selectedOption
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu variant="light">
        {filterable && (
          <Form
            className="d-flex dropdown-item p-2"
            onSubmit={(event) => event.preventDefault()}
          >
            <Form.Control
              type="search"
              placeholder="Search"
              aria-label="Search"
              onChange={filterList}
              autoFocus
            />
          </Form>
        )}
        <div
          style={{
            height: filteredOptions.length > 6 ? "200px" : "auto",
            overflowY: "auto",
          }}
        >
          {filteredOptions.map((item, index) => {
            return (
              <Dropdown.Item onClick={onClick} key={`drpdown-${index}`}>
                {item}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

CustomDropdown.defaultProps = {
  clipLength: 12,
  freeze: false,
  selectedValue: "",
};

export default memo(CustomDropdown);
