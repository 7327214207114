/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": process.env.REACT_APP_COGNITO_USERPOOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    "oauth": {
        "domain": process.env.REACT_APP_COGNITO_DOMAIN,
        "scope": [
            "phone",
            "email",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
        "redirectSignOut": process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
        "responseType": "code"
    },
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
