import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import { useDesignsContext } from '../Context/DesignsContext';

const RecentDesignContent = ({recentDesign }) => {
  const { openDesign } = useDesignsContext()
  const dateTimeFormatter = (dateTime) => {
    const date = new Date(dateTime)
    const formattedDate = date.toLocaleDateString("en-GB")
    const formattedTime = date.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })
    return `${formattedDate} ${formattedTime}`
  }
  return (
    <>
      <div className="card_resent_design_wrapper">
        {recentDesign?.length > 0 ? <><p className="card-headline">Previously explored</p>
        <div className="card_recent_design">
          {recentDesign?.map(design => {
            return (
              <>
                <Card variant="outlined" className="card_display"
                  key={design?.CHART_ID}
                  onClick={() => openDesign(design?.CHART_ID, design)}
                  role='button'>
                  <div className="card_display_table_name">
                    {design?.TABLE_NAME}
                  </div>
                  <div className="card_display_business_name">
                    {design?.BUSINESS_NAME}
                  </div>
                  <div className="card_display_updatedby">
                    <span className="card_label">Last updated by: </span>
                    <span className="card_text">{design?.UPDATED_BY}</span>
                  </div>
                  <div className='card_display_updated_timestamp'>
                    <span className="card_label">Last update timestamp: </span>
                    <span className="card_text">{dateTimeFormatter(design?.LAST_UPDATED)}</span>

                  </div>

                </Card>
              </>
            )
          })}
        </div> </>: ""}
       

      </div>

    </>
  );
};

export default RecentDesignContent;

