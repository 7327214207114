
import React, { useState, useRef } from "react";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import CancelIcon from '@mui/icons-material/Cancel';

const SearchTable = ({ searchWord, setSearchWord  }) => {
  const containerRef = useRef();

  const handleSearch = (event) => {
    const newSearch = event.target.value.toUpperCase()
    setSearchWord(newSearch)
  }

  const resetSearch = () => {
    setSearchWord("");
  }
  
  const hideCancelButton = searchWord !== "" ? false : true

  return (
    <div ref={containerRef} style = {{display: 'flex', alignItems: 'center'}}>
      <Form.Control
        className="mb-2"
        value={searchWord}
        onChange={handleSearch}
        
        type="text"
        placeholder="Search Table Name and Created Objects"
      />
      <div className={classNames('main-content-top-cancel-icon', {'cancel-icon-hidden': hideCancelButton})} onClick={resetSearch} style={{position:'relative', marginBottom:'8px', right:'32px'}}>
        <CancelIcon fontSize="small" />
      </div>
    </div>
  );
};

export default SearchTable;