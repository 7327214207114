import React, { useState, useEffect } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import TextField from '@mui/material/TextField';


const PaginationComponent = ({ account, totalPages, getData, currentPage, setCurrentPage, limit, selectedValue, database, searchResult }) => {
    useEffect(() => {
        if (account !== "" && limit !== undefined) {
            getData(account, currentPage, limit, selectedValue, database, searchResult);
        }
    }, [currentPage]);
    const setPageChange = (event) => {
        if (event.target.value <= totalPages || event.target.value >= 1) {
            setCurrentPage(parseInt(event.target.value));
        }
    };
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className='pagination-wrapper'>
            <FirstPageIcon aria-label="previous"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(1)} />
            <NavigateBeforeIcon onClick={handlePrevPage} disabled={currentPage === 1} />
            <TextField
                className="pagination-text-field"
                value={currentPage}
                onChange={setPageChange}
                id="outlined-number"
                type="number"
            />
            of {totalPages}
            <NavigateNextIcon aria-label="next" onClick={handleNextPage} disabled={currentPage === totalPages} />
            <LastPageIcon aria-label="next"
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(totalPages)} />
        </div>
    );
};

export default PaginationComponent;