import React, { useState, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import './CustomNodesStyle.css';
import Tooltip from '@mui/material/Tooltip'
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { usePlaygroundContext } from '../../../Context/PlaygroundContext';

const LinkNode = (node) => {
  const { onElementsRemove } = usePlaygroundContext()
  const { linkStatus, label } = node.data;
  const [warning, setWarning] = useState(true)

  const onDelete = () => {
    onElementsRemove([node])
  }

  useEffect(() => {
    setWarning(linkStatus)
  }, [linkStatus])

  return (
    <div className="linkNode">
      <Handle
        type="source"
        position="bottom"
        style={{ background: '#555' }}
      />
      <div className="py-1 px-2 position-relative">
        {label}
        <span className="position-absolute top-0 start-100 translate-middle badge fs-6" hidden={!warning}>
        <Tooltip 
          title="LINK node needs to be connected with minimum 2 HUB nodes" 
          placement="bottom-start" 
          arrow
        >
          <ErrorTwoToneIcon sx={{ fontSize: 25, color: 'red' }} />
        </Tooltip>
        </span>
        {node.selected && (
          <span className="ms-2" onClick={onDelete} style={{ cursor: 'pointer' }}>
            <Tooltip 
              title="Delete node along with its dependents" 
              placement="bottom-start" 
              arrow 
            >
              <DeleteForeverIcon style={{ fontSize: 'large', marginBottom: "0.175rem" }} />
            </Tooltip>
          </span>
          )
        }
      </div>
    </div>
  );
}

export default LinkNode;