import video1 from '../Assets/videos/dummy-video.mp4'

export const videos = [
    {
      title: "",
      videoUrl: video1,
    }
  //   {
  //     title: "Tutorial for Different Node Types",
  //     videoSrc:
  //       "https://drive.google.com/file/d/1QNClRuoYZZhJECB-uBvKyLvla7h0CdvF/preview",
  //   },
  ];